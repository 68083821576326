/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

const SelectGroup = ({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  options
}) => {
  return (
    <div className="select-group">
      {options.map(option => {
        let classes = ["select-group__btn"];
        if (value === option.value) classes.push("select-group__btn--selected");

        return (
          <button
            key={option.value}
            name={name}
            value={option.value}
            className={classes.join(" ")}
            onClick={e => {
              e.preventDefault();
              onChange(e);
            }}
          >
            {option.title}
          </button>
        );
      })}
    </div>
  );
};

SelectGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array
};

export default SelectGroup;
