import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Card, CardHeader, Image } from "../common";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HealthEventCard = ({ dog, event, t, onClick, calendar }) => {
  var displayDate = moment(event.date).format("L");
  var untilDate = event.end && moment(event.end).format("L");

  return (
    <Card calendar={calendar}>
      <CardHeader
        image={dog ? dog.photo : ""}
        title={
          t(`event.${event.eventType}`) +
          " " +
          (event.coach ? event.coach : "")
        }
        subTitle={untilDate ? displayDate + " - " + untilDate : displayDate}
        onClick={() => onClick(event.id)}
      />
      {event.person && event.person}
      {event.veterinarian && event.veterinarian}

      {event.eventType === "rest" && event.notes}

      {event.eventImageVariants &&
        event.eventImageVariants.original &&
        event.eventImageVariants.original.indexOf(".pdf") !== -1 && (
        <Document file={event.eventImageVariants.original}>
          <Page pageNumber={1} />
        </Document>
      )}
      {event.eventImageVariants &&
        event.eventImageVariants.original &&
        event.eventImageVariants.original.indexOf(".pdf") === -1 && (
        <Image src={event.eventImageVariants.hd} />
      )}

    </Card>
  );
};

HealthEventCard.propTypes = {
  dog: PropTypes.object,
  event: PropTypes.object,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  calendar: PropTypes.bool
};

HealthEventCard.defaultProps = {
  onClick: () => {},
};

export default withTranslation()(HealthEventCard);