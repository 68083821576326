import {
  FETCH_OTHER_EVENTS_FILTERED,
  ADD_OTHER_EVENT,
  UPDATE_OTHER_EVENT,
  DELETE_OTHER_EVENT
} from "./types";

export const fetchOtherEventsFiltered = (filters) => ({
  type: FETCH_OTHER_EVENTS_FILTERED.REQUEST,
  payload: { filters: filters },
});

export const fetchOtherEventsFilteredSucceeded = (otherEvents) => ({
  type: FETCH_OTHER_EVENTS_FILTERED.SUCCEEDED,
  payload: otherEvents,
});

export const fetchOtherEventsFilteredFailed = (error) => ({
  type: FETCH_OTHER_EVENTS_FILTERED.FAILED,
  payload: error,
});

export const addOtherEvent = ({ event, dog }) => ({
  type: ADD_OTHER_EVENT.REQUEST,
  payload: { event, dog },
});

export const addOtherEventSucceeded = (event) => ({
  type: ADD_OTHER_EVENT.SUCCEEDED,
  payload: event,
});

export const addOtherEventFailed = (error) => ({
  type: ADD_OTHER_EVENT.FAILED,
  payload: error,
});

export const updateOtherEvent = ({ event, dog }) => ({
  type: UPDATE_OTHER_EVENT.REQUEST,
  payload: { event, dog },
});

export const updateOtherEventSucceeded = (event) => ({
  type: UPDATE_OTHER_EVENT.SUCCEEDED,
  payload: event,
});

export const updateOtherEventFailed = (error) => ({
  type: UPDATE_OTHER_EVENT.FAILED,
  payload: error,
});

export const deleteOtherEvent = (event) => ({
  type: DELETE_OTHER_EVENT.REQUEST,
  payload: event,
});

export const deleteOtherEventSucceeded = (id) => ({
  type: DELETE_OTHER_EVENT.SUCCEEDED,
  payload: id,
});

export const deleteOtherEventFailed = (error) => ({
  type: DELETE_OTHER_EVENT.FAILED,
  payload: error,
});
