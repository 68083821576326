import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import AuthReducer from "./AuthReducer";
import UserReducer from "./UserReducer";
import DogReducer from "./DogReducer";
import ActiveDogReducer from "./ActiveDogReducer";
import TrainingReducer from "./TrainingReducer";
import NotificationReducer from "./NotificationReducer";
import TrialReducer from "./TrialReducer";
import TopicReducer from "./TopicReducer";
import FocusReducer from "./FocusReducer";
import SettingsReducer from "./SettingsReducer";
import AdminReducer from "./AdminReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import CouponReducer from "./CouponReducer";
import StatisticReducer from "./StatisticReducer";
import OrganizationReducer from "./OrganizationReducer";
import SizeClassReducer from "./SizeClassReducer";
import CourseTypeReducer from "./CourseTypeReducer";
import TrialFilterReducer from "./TrialFilterReducer";
import TrainingFilterReducer from "./TrainingFilterReducer";
import OtherEventReducer from "./OtherEventReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    user: UserReducer,
    dogs: DogReducer,
    activeDog: ActiveDogReducer,
    trainings: TrainingReducer,
    notification: NotificationReducer,
    trials: TrialReducer,
    topics: TopicReducer,
    focuses: FocusReducer,
    settings: SettingsReducer,
    admin: AdminReducer,
    subscriptions: SubscriptionReducer,
    coupons: CouponReducer,
    statistics: StatisticReducer,
    organizations: OrganizationReducer,
    sizeClasses: SizeClassReducer,
    courseTypes: CourseTypeReducer,
    trialFilters: TrialFilterReducer,
    trainingFilters: TrainingFilterReducer,
    otherEvents: OtherEventReducer,
  });
