import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Image } from "../../common";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const OtherEventView = (props) => {

  const { t, event, history } = props;

  return (
    <> 
      <button className="trial-back-button" onClick={() => history.push("/calendar")}>
        <strong>&larr;</strong> {t("common.back")}
      </button>
      <h1 className="text-center">{moment(event.date).format("L")}
        {event.end ? " - " + moment(event.end).format("L") : ""}</h1>
      <h1 className="text-center">{t(`event.${event.eventType}`)}</h1>

      <br />

      <div>
        {event.person &&
          <div>
            <p className="tag no-margin">{t("event.carePerson")}</p>
            <span className="h1"> {event.person}</span>
          </div>}
        {event.veterinarian &&
          <div>
            <p className="tag no-margin">{t("event.veterinarian")}</p>
            <span className="h1">{event.veterinarian}</span>
          </div>}
      </div>

      {event.videoUrl && event.videoUrl.indexOf("instagram") === -1 && (
        <div className="p">
          <ReactPlayer url={event.videoUrl} controls width="100%" />
        </div>
      )}

      {event.videoUrl && event.videoUrl.indexOf("instagram") !== -1 && (
        <div className="p">
          <InstagramEmbed url={event.videoUrl} />
        </div>
      )}

      <p className="multiLine">{event.notes}</p>

      {event.eventImage && (
        <div className="p">
          {event.eventImage.indexOf(".pdf") !== -1 && (
            <Document file={event.eventImage}>
              <Page pageNumber={1} />
            </Document>
          )}
          {event.eventImage.indexOf(".pdf") === -1 && (
            <Image src={event.eventImage} alt={t("event.eventImage")} />
          )}
        </div>
      )}
    </>
  );
};

OtherEventView.propTypes = {
  t: PropTypes.func,
  event: PropTypes.object,
  history: PropTypes.object,
};

export default withTranslation()(OtherEventView);