import React from "react";
import moment from "moment";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import { Document, Page, pdfjs } from "react-pdf";
import { Icon, Image, Stars } from "../../common";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/*
 * Used in ExerciseExpandedList, TrainingTopicEdit and SubTopicEdit for
 * rendering sortable list of exercises.
 * Used also in SharedTrainingTopic and SharedSubtopicView without a onMoveToTraining and onCheck.
 */
const SortableHandleHelper = SortableHandle(() => (
  <div
    className="todo-list__sort-handle ml-2"
    onClick={e => e.stopPropagation()}
  >
    <Icon icon="arrows" />
  </div>
));

const ExerciseSortableItem = SortableElement(({ value: exercise, //onItemDelete,
  onMoveToTraining, //onItemEdit,
  onItemView, onExpand, onViewTraining, onCheck, greyIndex, t, hasActivePlan }) => (
  <li
    key={exercise.id}
    className={`${greyIndex % 2 === 0 ? "bg-grey p-2" : "p-2"}`}
    onClick={
      greyIndex !== -1 ? e => onExpand(exercise.id) : e => e.preventDefault()
    }
  >
    <div className="column column--align-top">
      {onCheck ? (
        <React.Fragment>
          <SortableHandleHelper />
          <div>
            <input
              type="checkbox"
              className="todo-list__input"
              value={exercise.id}
              checked={exercise.done}
              onChange={onCheck}
              onClick={e => e.stopPropagation()}
              readOnly
            />
          </div>
        </React.Fragment>
      ) : (
        <span className="mr-2" />
      )}
      <label className="todo-list__label">
        <span className="todo-list__name">{exercise.name}</span>
      </label>
      {/* {greyIndex !== -1 ? (
          <button
            className="todo-list__delete btn--link px-2"
            onClick={e => onItemEdit(exercise.id, e)}
          >
            <Icon icon="editGrey" />
          </button>
        ) : ( */}
      <button
        className="todo-list__delete btn--link px-2"
        onClick={e => onItemView(exercise.id, e)}
      >
        <Icon icon="view" />
      </button>
      {/* )} */}
      {/* <button
          className="todo-list__delete btn--link p-2"
          onClick={e => e.stopPropagation()}
          // onClick={e => onItemDelete(exercise.id, e)}
        >
          <Icon icon="times" />
        </button> */}
    </div>

    {exercise.expanded ? (
      <div className="m-3">
        <p className="clearfix multiLine">{exercise.notes}</p>
        <div className="m-3">
          {exercise.courseMapVariants && exercise.courseMapVariants.original && (
            <div className="d-flex justify-content-center">
              <div className="d-block h-auto">
                {exercise.courseMapVariants.original.indexOf(".pdf") !==
                  -1 && (
                  <Document
                    file={exercise.courseMapVariants.original}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  >
                    <Page pageNumber="1" />
                  </Document>
                )}
                {exercise.courseMapVariants.original.indexOf(".pdf") ===
                   -1 && <Image src={exercise.courseMapVariants.hd} />}
              </div>
            </div>
          )}
          <p />
          {exercise.videoUrl &&
            exercise.videoUrl.indexOf("instagram") === -1 && (
            <ReactPlayer url={exercise.videoUrl} controls width="100%" />
          )}
          {exercise.videoUrl &&
            exercise.videoUrl.indexOf("instagram") !== -1 && (
            <InstagramEmbed url={exercise.videoUrl} />
          )}
        </div>
        <p />

        {onMoveToTraining && (
          <div className="d-flex justify-content-center">
            {hasActivePlan ? (
              <button
                className="btn btn-outline-success"
                onClick={e => onMoveToTraining(exercise.id, e)}
              >
                {t("topics.moveToTraining")}
              </button>
            ) : (
              <button
                className="btn btn-outline-secondary"
                onClick={e => e.stopPropagation()}
              >
                {t("topics.moveToTraining")}
              </button>
            )}
          </div>
        )}
        <p />
        {exercise.trainedExercises && exercise.trainedExercises.length > 0 ? (
          <div>
            <p className="m-0">{t("topics.trained")}:</p>
            <ul className="list list--simple">
              {exercise.trainedExercises
                .sort(
                  (a, b) =>
                    new Date(b.training.date) - new Date(a.training.date)
                )
                .map((trained, index) => {
                  return (
                    <li key={index}>
                      <button
                        className="btn--link"
                        onClick={e => onViewTraining(trained.training.id, e)}
                      >
                        <span className="text-info">
                          {moment(trained.training.date).format("ll")}
                        </span>
                      </button>
                      {trained.training.rating ? (
                        <Stars value={trained.training.rating} size="small" />
                      ) : (
                        <p />
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          <div />
        )}
      </div>
    ) : (
      <div />
    )}
    {greyIndex !== -1 ? (
      <img
        src={require("../../images/icons/chevron-down.svg").default}
        className={`${
          exercise.expanded
            ? "icon center-icon rotated-icon"
            : "icon center-icon"
        }`}
        alt={"expand-contract"}
      />
    ) : (
      <div />
    )}
  </li>
));

export const ExerciseSortableList = SortableContainer(
  ({
    items: exercises,
    onItemDelete,
    onMoveToTraining,
    onItemEdit,
    onItemView,
    onExpand,
    onViewTraining,
    onCheck,
    topicList,
    t,
    hasActivePlan
  }) => {
    return (
      <ul className={`${topicList ? "todo-list px-2" : "todo-list"}`}>
        {exercises.map((exercise, index) => (
          <ExerciseSortableItem
            key={`item-${exercise.id}`}
            index={index}
            value={exercise}
            onItemDelete={onItemDelete}
            onMoveToTraining={onMoveToTraining}
            onItemEdit={onItemEdit}
            onItemView={onItemView}
            onExpand={onExpand}
            onViewTraining={onViewTraining}
            onCheck={onCheck}
            greyIndex={!topicList ? index : -1}
            t={t}
            hasActivePlan={hasActivePlan}
          />
        ))}
      </ul>
    );
  }
);
