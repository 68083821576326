import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { addOtherEvent, updateOtherEvent } from "../../actions";
import {
  AppContent,
  FormGroup,
  Input,
  TextArea,
  RoundButton,
  SuggestInput,
  Error,
  ImageUploader,
  SelectGroup,
} from "../../common";
import PaywallNotification from "../../containers/App/PaywallNotification";
import Api from "../../helpers/Api";


const OtherEventEdit = (props) => {

  const { t, event, history, saving, error, hasActivePlan } = props;

  const eventTypeOptions = [    
    { title: t("event.care"), value: "care" }, 
    { title: t("event.veterinarian"), value: "veterinarian" },
    { title: t("event.rest"), value: "rest" },
  ];

  const [eventForm, setEventForm] = useState({
    person: "",
    personId: null,
    veterinarian: "",
    veterinarianId: null,
    date: null,
    end: null,
    eventType: eventTypeOptions[0].value,
    notes: "",
    eventImage: "",
  });
  const [edit, setEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [personSuggestions, setPersonSuggestions] = useState([]);
  const [veterinarianSuggestions, setVeterinarianSuggestions] = useState([]);

  useEffect(() => {
    const { id, date } = props.match.params;

    if (id) {
      const date = moment(event.date).format("YYYY-MM-DD");
      const end = event.end && moment(event.end).format("YYYY-MM-DD");
      setEventForm({ ...event, date, end });
      setEdit(true);
    } else if (date) {
      setEventForm({...eventForm, date });
    } else {
      const date = moment().format("YYYY-MM-DD");
      setEventForm({...eventForm, date });
    }
    console.log("saving: "+ saving + " submitted: " + submitted);
    if (submitted && !saving && !error) history.push("/calendar/");
  }, [saving, submitted]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setEventForm({ ...eventForm, [name]: value });
  };

  // Person methods
  const handlePersonChange = (e, { newValue }) => {
    setEventForm({ ...eventForm, person: newValue, personId: null });
  };

  const setPersonId = () => {
    const { person } = eventForm;
    const suggestion = personSuggestions.find((l) => l.name === person);

    if (suggestion)
      setEventForm({ ...eventForm, personId: suggestion.id });
  };

  const handleVeterinarianChange = (e, { newValue }) => {
    setEventForm({ ...eventForm, veterinarian: newValue, veterinarianId: null });
  };

  const setVeterinarianId = () => {
    const { veterinarian } = eventForm;
    const suggestion = veterinarianSuggestions.find((l) => l.name === veterinarian);

    if (suggestion)
      setEventForm({ ...eventForm, veterinarianId: suggestion.id });
  };

  const fetchPersons = (e) => {
    const { auth_token } = props;
    Api({ auth_token })
      .fetchPersons(e.value)
      .then((res) =>
        setPersonSuggestions(res.data || [])
      );
  };
  const fetchVeterinarians = (e) => {
    const { auth_token } = props;
    Api({ auth_token })
      .fetchVeterinarians(e.value)
      .then((res) =>
        setVeterinarianSuggestions(res.data || [])
      );
  };

  const handleEventImageUpload = (image) => {
    setEventForm({
      ...eventForm,
      eventImage: image.imagePreviewUrl,
      eventImageFile: image.file,
    });
  };

  const validate = () => {
    const { t } = props;
    const { date } = eventForm;
    let errors = {};

    if (!date) errors.date = t("error.required");

    setErrors(errors);
    return _.isEmpty(errors);
  };

  const handleSubmit = () => {
    const { dog, addOtherEvent, updateOtherEvent } = props;
    const isValid = validate();

    if (!isValid) return false;
    console.log(eventForm);
    if (edit) {
      const id = eventForm.id;
      updateOtherEvent({
        event: eventForm,
        dog,
        id,
      });
    } else {
      addOtherEvent({ event: eventForm, dog });
    }
    setSubmitted(true);
  };

  return (
    <AppContent size="medium">
      <FormGroup>
        <SelectGroup
          label={t("event.type")}
          type="text"
          name="eventType"
          value={eventForm.eventType}
          options={eventTypeOptions}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Input
          label={t("common.date")}
          type="date"
          name="date"
          value={eventForm.date}
          onChange={handleInputChange}
          disabled={!hasActivePlan}
        />
        <Error show={!!errors.date}>{errors.date}</Error>
      </FormGroup>
      {eventForm.eventType === "rest" &&
        <FormGroup>
          <Input
            label={t("event.endDate")}
            type="date"
            name="end"
            value={eventForm.end}
            onChange={handleInputChange}
            disabled={!hasActivePlan}
          />
        </FormGroup>
      }
      {eventForm.eventType === "care" &&
      <FormGroup>
        {hasActivePlan ? <SuggestInput
          value={eventForm.person || ""}
          onChange={handlePersonChange}
          onBlur={setPersonId}
          suggestions={personSuggestions}
          name="person"
          label={t("event.carePerson")}
          onFetch={fetchPersons}
          onClear={() => setPersonSuggestions([])}
          suggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}         
        /> : <Input
          name="person"
          label={t("event.carePerson")}
          disabled={!hasActivePlan} />
        }
      </FormGroup>
      }
      {eventForm.eventType === "veterinarian" &&
      <FormGroup>
        {hasActivePlan ? <SuggestInput
          value={eventForm.veterinarian || ""}
          onChange={handleVeterinarianChange}
          onBlur={setVeterinarianId}
          suggestions={veterinarianSuggestions}
          name="veterinarian"
          label={t("event.veterinarian")}
          onFetch={fetchVeterinarians}
          onClear={() => setVeterinarianSuggestions([])}
          suggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}         
        /> : <Input
          name="veterinarian"
          label={t("event.veterinarian")} 
          disabled={!hasActivePlan}/>
        }
      </FormGroup>
      }
      <FormGroup>
        <TextArea
          label={t("training.notes")}
          type="text"
          name="notes"
          value={eventForm.notes}
          onChange={handleInputChange}
          disabled={!hasActivePlan}
        />
      </FormGroup>
      
      <FormGroup>
        {hasActivePlan ?
          <ImageUploader
            buttonLabel={t("event.eventImageButton")}
            imageUrl={eventForm.eventImage}
            onUpload={handleEventImageUpload}
          /> :
          <button className="btn btn-secondary btn-block">{t("event.eventImageButton")}</button>}
      </FormGroup>
      {hasActivePlan && 
        <RoundButton
          atCorner
          size="large"
          icon={saving ? "loader" : "complete"}
          onClick={handleSubmit}
          disabled={!hasActivePlan}
        />
      }
      {!hasActivePlan && <PaywallNotification text=""
        button="subscription.noPlanYet"/> }
    </AppContent>
  );
};

OtherEventEdit.propTypes = {
  t: PropTypes.func,
  event: PropTypes.object,
  history: PropTypes.object,
  auth_token: PropTypes.any,
  saving: PropTypes.bool, 
  error: PropTypes.any,
  dog: PropTypes.object, 
  addOtherEvent: PropTypes.func, 
  updateOtherEvent: PropTypes.func,
  match: PropTypes.object,
  hasActivePlan: PropTypes.bool

};

const mapStateToProps = (
  { activeDog, otherEvents, auth, subscriptions },
  props
) => {
  const { id } = props.match.params;
  const event = otherEvents.otherEvents.find((t) => t.id === Number(id));

  return {
    dog: activeDog,
    event: event,
    saving: otherEvents.saving,
    error: otherEvents.error,
    auth_token: auth.authToken,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};


const OtherEventEditWithTranslate = withTranslation()(OtherEventEdit);
export default connect(mapStateToProps, { addOtherEvent, updateOtherEvent })(
  OtherEventEditWithTranslate
);