import {
  FETCH_TRAININGS,
  ADD_TRAINING,
  UPDATE_TRAINING,
  DELETE_TRAINING,
  LOGOUT,
  AFTER_REHYDRATE,
  FETCH_TRAININGS_PAGED,
  FETCH_TRAININGS_PAGES_COUNT,
  FETCH_TRAININGS_COUNT,
  SET_ACTIVE_DOG,
  FETCH_TRAININGS_FILTERED,
} from "../actions/types";

const INITIAL_STATE = {
  trainings: [],
  trainingsPagesCount: 0,
  trainingsPage: 1,
  trainingsCount: 0,
  fetchingTrainings: false,
  fetchingTrainingsFiltered: false,
  fetchingTrainingsPaged: false,
  fetchingTrainingsPagesCount: false,
  fetchingTrainingsCount: false,
  saving: false,
  error: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  // Fetch
  case FETCH_TRAININGS.REQUEST:
    return { ...state, fetchingTrainings: true, error: null, trainings: [] };
  case FETCH_TRAININGS.SUCCEEDED:
    return { ...state, fetchingTrainings: false, trainings: payload };
  case FETCH_TRAININGS.FAILED:
    return { ...state, fetchingTrainings: false, error: payload };

  case FETCH_TRAININGS_FILTERED.REQUEST:
    return { ...state, fetchingTrainingsFiltered: true, error: null };
  case FETCH_TRAININGS_FILTERED.SUCCEEDED:
    return {
      ...state,
      fetchingTrainingsFiltered: false,
      trainings: payload.trainings};
  case FETCH_TRAININGS_FILTERED.FAILED:
    return { ...state, fetchingTrainingsFiltered: false, error: payload };

  case FETCH_TRAININGS_PAGED.REQUEST:
    return { ...state, fetchingTrainingsPaged: true, error: null };
  case FETCH_TRAININGS_PAGED.SUCCEEDED:
    return {
      ...state,
      fetchingTrainingsPaged: false,
      trainings:
          payload.trainingsPage === 1
            ? payload.trainings
            : state.trainings.concat(payload.trainings),
      trainingsPage: payload.trainingsPage,
    };
  case FETCH_TRAININGS_PAGED.FAILED:
    return { ...state, fetchingTrainingsPaged: false, error: payload };

  case FETCH_TRAININGS_PAGES_COUNT.REQUEST:
    return { ...state, fetchingTrainingsPagesCount: true, error: null };
  case FETCH_TRAININGS_PAGES_COUNT.SUCCEEDED:
    return {
      ...state,
      fetchingTrainingsPagesCount: false,
      trainingsPagesCount: payload,
    };
  case FETCH_TRAININGS_PAGES_COUNT.FAILED:
    return { ...state, fetchingTrainingsPagesCount: false, error: payload };

  case FETCH_TRAININGS_COUNT.REQUEST:
    return { ...state, fetchingTrainingsCount: true, error: null };
  case FETCH_TRAININGS_COUNT.SUCCEEDED:
    return {
      ...state,
      fetchingTrainingsCount: false,
      trainingsCount: payload,
    };
  case FETCH_TRAININGS_COUNT.FAILED:
    return { ...state, fetchingTrainingsCount: false, error: payload };

    // Add
  case ADD_TRAINING.REQUEST:
    return { ...state, saving: true, error: null };
  case ADD_TRAINING.SUCCEEDED:
    return {
      ...state,
      saving: false,
      trainings: [...state.trainings, payload],
    };
  case ADD_TRAINING.FAILED:
    return { ...state, saving: false, error: payload };

    // Update
  case UPDATE_TRAINING.REQUEST:
    return { ...state };
  case UPDATE_TRAINING.SUCCEEDED:
    return {
      ...state,
      trainings: state.trainings.map((t) =>
        t.id === payload.id ? payload : t
      ),
    };
  case UPDATE_TRAINING.FAILED:
    return { ...state };

    // Delete
  case DELETE_TRAINING.REQUEST:
    return { ...state };
  case DELETE_TRAINING.SUCCEEDED:
    return {
      ...state,
      trainings: state.trainings.filter((t) => t.id !== payload),
    };
  case DELETE_TRAINING.FAILED:
    return { ...state };

  case LOGOUT.REQUEST:
    return { ...INITIAL_STATE };

  case AFTER_REHYDRATE:
    return { ...state, saving: false, fetchingTrainings: false };

  case SET_ACTIVE_DOG:
    return INITIAL_STATE;

  default:
    return state;
  }
};
