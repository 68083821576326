import React from "react";
import PropTypes from "prop-types";

const icons = {
  home: require("../images/icons/home.svg").default,
  homeWhite: require("../images/icons/home_white.svg").default,
  noteBook: require("../images/icons/note-book.svg").default,
  medal: require("../images/icons/medal.svg").default,
  list: require("../images/icons/list.svg").default,
  chevronLeft: require("../images/icons/chevron-left.svg").default,
  bars: require("../images/icons/bars.svg").default,
  arrows: require("../images/icons/arrows.svg").default,
  times: require("../images/icons/times.svg").default,
  upload: require("../images/icons/upload.svg").default,
  reload: require("../images/icons/reload.svg").default,
  arrowRight: require("../images/icons/arrow-right-grey.svg").default,
  editGrey: require("../images/icons/edit-grey.svg").default,
  view: require("../images/icons/view-grey.svg").default,
  logo: require("../images/icons/logo.svg").default,
  calendar: require("../images/icons/calendar.svg").default,
  vetenarian: require("../images/icons/vetenarian.svg").default,
  healthcare: require("../images/icons/heart-paw.svg").default,
  holiday: require("../images/icons/holiday.svg").default,
};

const Icon = ({ icon, className }) => {
  const iconURL = icons[icon];
  return (
    <img
      src={iconURL}
      className={className ? className : "icon"}
      alt={`icon like ${icon}`}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string
};

export default Icon;
