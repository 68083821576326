import axios from "axios";
import _ from "lodash";
import moment from "moment";

import { formatForApi } from "./ApiTransformer";
import { formatForApp } from "./AppTransformer";
import { api } from "../config/env";
const baseURL = api.url;

const Api = (headers) => {
  const request = () =>
    axios.create({
      baseURL,
      headers,
    });

  const login = (user) => {
    return request().post("/v01/auth/login", {
      ...formatForApi.user(user),
    });
  };
  // eslint-disable-next-line no-unused-vars
  const logout = (authToken) => {
    return request().delete("/v01/auth/logout");
  };

  const signup = (user) => {
    return request()
      .post("/v01/signup", {
        user: formatForApi.user(user),
        "g-recaptcha-response": user.recaptcha,
      })
      .then((res) => {
        res.data = formatForApp.user(res.data);
        return res;
      });
  };

  const returnPassword = ({ email, recaptcha }) =>
    request().post("/v01/forgot_password", {
      email,
      "g-recaptcha-response": recaptcha,
    });

  const fetchUser = (id) => {
    return request()
      .get(`/v01/users/${id}`)
      .then((res) => {
        res.data = formatForApp.user(res.data);
        return res;
      });
  };

  const updateUser = (user) => {
    user = { ...formatForApi.user(user) };

    let formData = new FormData();
    formData = appendFormdata(formData, user, "user");

    return request()
      .put(`/v01/users/${user.id}`, formData)
      .then((res) => {
        res.data = formatForApp.user(res.data);
        return res;
      });
  };

  const saveSettings = (settings) => {
    return request()
      .put(`/v01/users/${settings.userId}`, {
        user: formatForApi.user(settings),
      })
      .then((res) => {
        res.data = formatForApp.user(res.data);
        return res;
      });
  };

  const fetchDogs = () => {
    return request()
      .get("/v01/dogs/")
      .then((res) => {
        res.data = res.data.map((dog) => formatForApp.dog(dog));
        return res;
      });
  };

  const addDog = (dog) => {
    dog = { ...formatForApi.dog(dog) };

    let formData = new FormData();
    formData = appendFormdata(formData, dog, "dog");

    return request()
      .post("/v01/dogs/", formData)
      .then((res) => {
        res.data = formatForApp.dog(res.data);
        return res;
      });
  };

  const updateDog = (dog) => {
    dog = { ...formatForApi.dog(dog) };

    let formData = new FormData();
    formData = appendFormdata(formData, dog, "dog");

    return request()
      .put(`/v01/dogs/${dog.id}`, formData)
      .then((res) => {
        res.data = formatForApp.dog(res.data);
        return res;
      });
  };

  const deleteDog = (id) => {
    return request().delete(`/v01/dogs/${id}`);
  };

  const fetchDog = (id) => {
    return request()
      .get(`/v01/dogs/${id}`)
      .then((res) => {
        res.data = formatForApp.dog(res.data);
        return res;
      });
  };

  const updateDogTrialSizeClass = (dog) => {
    return request()
      .post(`/v01/dogs/${dog.id}/update_trial_size_class`, {
        size_class_id: dog.sizeClasses[0].id,
        organization_id: dog.sizeClasses[0].organizationId,
      })
      .then((res) => {
        return res;
      });
  };

  //TODO: remove
  // const fetchTrainings = ({ dog }) => {
  //   return request()
  //     .get(`/v01/dogs/${dog.id}/trainings/trainings_all`)
  //     .then((res) => {
  //       res.data = res.data.map((training) => formatForApp.training(training));
  //       return res;
  //     });
  // };

  const fetchTrainingsPaged = ({ dog, trainingsPage, filters }) => {
    let filterStr = getTrainingFilters(filters);
    return request()
      .get(`/v01/dogs/${dog.id}/trainings/?${filterStr}&page=${trainingsPage}`)
      .then((res) => {
        res.data = res.data.map((training) => formatForApp.training(training));
        return res;
      });
  };

  const fetchTrainingsFiltered = ({ dog, filters }) => {
    let filterStr = getTrainingFilters(filters);
    return request()
      .get(`/v01/dogs/${dog.id}/trainings/?${filterStr}`)
      .then((res) => {
        res.data = res.data.map((training) => formatForApp.training(training));
        return res;
      });
  };

  const fetchTrainingsPagesCount = ({ dog, filters }) => {
    let filterStr = getTrainingFilters(filters);
    return request().get(
      `/v01/dogs/${dog.id}/trainings/trainings_pages_count/?${filterStr}`
    );
  };

  const fetchTrainingsCount = ({ dog, filters }) => {
    let filterStr = getTrainingFilters(filters);
    if (dog && !_.isEmpty(dog)) {
      return request().get(
        `/v01/dogs/${dog.id}/trainings/trainings_count/?${filterStr}`
      );
    } else {
      return false;
    }
  };

  const addTraining = ({ training, dog }) => {
    training.focuses = formatForApi.trainingFocusCategories(training.focuses);
    training = { ...formatForApi.training(training) };
    if (!training.video_url) delete training.video_url;
    if (!training.course_map) delete training.course_map;
    if (!training.coach_name) delete training.coach_name;
    if (!training.coach_id) delete training.coach_id;

    let formData = new FormData();
    formData = appendFormdata(formData, training, "training");

    return request()
      .post(`/v01/dogs/${dog.id}/trainings`, formData)
      .then((res) => {
        res.data = formatForApp.training(res.data);
        return res;
      });
  };

  const updateTraining = ({ training, dog, id }) => {
    training.focuses = formatForApi.trainingFocusCategories(training.focuses);
    training = { ...formatForApi.training(training) };

    if (!training.video_url) delete training.video_url;
    if (!training.course_map) delete training.course_map;
    if (!training.coach_name) delete training.coach_name;
    if (!training.coach_id) delete training.coach_id;

    let formData = new FormData();
    formData = appendFormdata(formData, training, "training");

    return request()
      .put(`/v01/dogs/${dog.id}/trainings/${id}`, formData)
      .then((res) => {
        res.data = formatForApp.training(res.data);
        return res;
      });
  };

  const deleteTraining = ({ dog, id }) => {
    return request().delete(`/v01/dogs/${dog.id}/trainings/${id}`);
  };

  //TODO: remove?
  // const fetchTrials = ({ dog }) => {
  //   return request()
  //     .get(`/v01/dogs/${dog.id}/trials/trials_all`)
  //     .then((res) => {
  //       res.data = res.data.map((trial) => formatForApp.trial(trial));
  //       return res;
  //     });
  // };

  const fetchTrialsFiltered = ({ dog, filters }) => {
    let filterStr = getTrialFilters(filters);
    return request()
      .get(`/v01/dogs/${dog.id}/trials/?${filterStr}`)
      .then((res) => {
        res.data = res.data.map((trial) => formatForApp.trial(trial));
        return res;
      });
  };

  const fetchTrialsPaged = ({ dog, trialsPage, filters }) => {
    let filterStr = getTrialFilters(filters);
    return request()
      .get(`/v01/dogs/${dog.id}/trials/?${filterStr}&page=${trialsPage}`)
      .then((res) => {
        res.data = res.data.map((trial) => formatForApp.trial(trial));
        return res;
      });
  };

  const fetchTrialsPagesCount = ({ dog, filters }) => {
    if (dog && !_.isEmpty(dog)) {
      let filterStr = getTrialFilters(filters);
      return request().get(
        `/v01/dogs/${dog.id}/trials/trials_pages_count/?${filterStr}`
      );
    } else {
      return false;
    }
  };

  const fetchTrialsCount = ({ dog, filters }) => {
    if (dog && !_.isEmpty(dog)) {
      let filterStr = getTrialFilters(filters);
      return request().get(
        `/v01/dogs/${dog.id}/trials/trials_count/?${filterStr}`
      );
    } else {
      return false;
    }
  };

  const addTrial = ({ trial, dog }) => {
    trial = { ...formatForApi.trial(trial) };
    trial.trial_faults = formatForApi.trainingFocusCategories(
      trial.trial_faults
    );
    trial.trial_fault_reasons = formatForApi.trainingFocusCategories(
      trial.trial_fault_reasons
    );

    if (!trial.video_url) delete trial.video_url;
    if (!trial.course_map) delete trial.course_map;

    let formData = new FormData();
    formData = appendFormdata(formData, trial, "trial");

    return request()
      .post(`/v01/dogs/${dog.id}/trials`, formData)
      .then((res) => {
        res.data = formatForApp.trial(res.data);
        return res;
      });
  };

  const updateTrial = ({ trial, dog }) => {
    // if (!trial.courseMap) delete trial.courseMap;
    trial = { ...formatForApi.trial(trial) };
    trial.trial_faults = formatForApi.trainingFocusCategories(
      trial.trial_faults
    );
    trial.trial_fault_reasons = formatForApi.trainingFocusCategories(
      trial.trial_fault_reasons
    );

    if (!trial.video_url) delete trial.video_url;
    if (!trial.course_map) delete trial.course_map;

    let formData = new FormData();
    formData = appendFormdata(formData, trial, "trial");

    return request()
      .put(`/v01/dogs/${dog.id}/trials/${trial.id}`, formData)
      .then((res) => {
        res.data = formatForApp.trial(res.data);
        return res;
      });
  };

  const deleteTrial = (trial) => {
    return request().delete(`/v01/dogs/${trial.dogId}/trials/${trial.id}`);
  };

  /* Other events */
  const fetchOtherEventsFiltered = ({ dog, filters }) => {
    let filterStr = getOtherEventFilters(filters);
    return request()
      .get(`/v01/dogs/${dog.id}/events/?${filterStr}`)
      .then((res) => {
        res.data = res.data.map((event) => formatForApp.event(event));
        return res;
      });
  };

  const addOtherEvent = ({ event, dog }) => {
    event = { ...formatForApi.event(event) };

    if (!event.photo) delete event.photo;
    if (!event.person_name) delete event.person_name;
    if (!event.person_id) delete event.person_id;
    if (!event.veterinarian_name) delete event.veterinarian_name;
    if (!event.veterinarian_id) delete event.veterinarian_id;

    let formData = new FormData();
    formData = appendFormdata(formData, event, "event");

    return request()
      .post(`/v01/dogs/${dog.id}/events`, formData)
      .then((res) => {
        res.data = formatForApp.event(res.data);
        return res;
      });
  };

  const updateOtherEvent = ({ event, dog }) => {
    event = { ...formatForApi.event(event) };

    if (!event.event_image) delete event.event_image;
    if (!event.person_name) delete event.person_name;
    if (!event.person_id) delete event.person_id;
    if (!event.veterinarian_name) delete event.veterinarian_name;
    if (!event.veterinarian_id) delete event.veterinarian_id;

    let formData = new FormData();
    formData = appendFormdata(formData, event, "event");

    return request()
      .put(`/v01/dogs/${dog.id}/events/${event.id}`, formData)
      .then((res) => {
        res.data = formatForApp.event(res.data);
        return res;
      });
  };

  const deleteOtherEvent = ( event ) => {
    return request().delete(`/v01/dogs/${event.dogId}/events/${event.id}`);
  };

  
  /* Training topics */
  const fetchTrainingTopics = ({ dogId }) => {
    return request()
      .get(`/v01/dogs/${dogId}/training_topics`)
      .then((res) => {
        res.data = res.data.map((item) => formatForApp.topic(item));
        return res;
      });
  };

  const fetchSharedTopics = () => {
    return request()
      .get("/v01/shared_training_topics")
      .then((res) => {
        res.data = res.data.map((topic) => formatForApp.sharedTopic(topic));
        return res;
      });
  };

  const shareTopic = ({ topicId, dog }) => {
    return request()
      .post(`/v01/dogs/${dog.id}/training_topics/${topicId}/share`)
      .then((res) => {
        res.data = formatForApp.topic(res.data);
        return res;
      });
  };

  const unShareTopic = ({ topicId, dog }) => {
    return request()
      .post(`/v01/dogs/${dog.id}/training_topics/${topicId}/unshare`)
      .then((res) => {
        return res;
      });
  };

  const getSharedTopic = ({ topicId }) => {
    return request()
      .get(`/v01/shared_training_topics/${topicId}`)
      .then((res) => {
        res.data = formatForApp.topic(res.data);
        return res;
      });
  };
  // eslint-disable-next-line no-unused-vars
  const copySharedTopic = ({ topicId, dogId, dogIdToCopy }) => {
    return request()
      .post(`/v01/shared_training_topics/${topicId}/copy`, {
        dog_id_to_copy: dogIdToCopy,
      })
      .then((res) => {
        // TODO: What does it return and does anything need to be done?
        return res;
      });
  };

  const addTrainingTopic = ({ dogId, topic }) => {
    return request()
      .post(`/v01/dogs/${dogId}/training_topics`, {
        training_topic: {
          done: false,
          name: topic,
          order_number: 999,
        },
      })
      .then((res) => {
        res.data = formatForApp.topic(res.data);
        return res;
      });
  };

  const updateTrainingTopic = ({ dogId, topic }) => {
    topic = { ...formatForApi.topic(topic) };
    // topic.training_sub_topics = formatForApi.trainingSubTopics(topic.training_sub_topics);

    return request()
      .patch(`/v01/dogs/${dogId}/training_topics/${topic.id}`, {
        training_topic: {
          done: topic.done,
          name: topic.name,
          notes: topic.notes,
          order_number: topic.order_number,
          training_sub_topics: topic.training_sub_topics,
          training_focuses: topic.training_focuses,
          exercises: topic.exercises,
        },
      })
      .then((res) => {
        res.data = formatForApp.topic(res.data);
        return res;
      });
  };

  const addTrainingTopicForm = ({ dogId, topic }) => {
    topic = { ...formatForApi.topic(topic) };

    let formData = new FormData();
    formData = appendFormdata(formData, topic, "training_topic");

    return request()
      .post(`/v01/dogs/${dogId}/training_topics`, formData)
      .then((res) => {
        res.data = formatForApp.topic(res.data);
        return res;
      });
  };

  const deleteTrainingTopic = ({ dogId, topicId }) => {
    return request()
      .delete(`/v01/dogs/${dogId}/training_topics/${topicId}`)
      .then((res) => {
        return res;
      });
  };

  const copyTrainingTopic = ({ topicId, dogId, dogIdToCopy }) => {
    return request()
      .post(`/v01/dogs/${dogId}/training_topics/${topicId}/copy`, {
        dog_id_to_copy: dogIdToCopy,
      })
      .then((res) => {
        // TODO: What does it return and does anything need to be done?
        return res;
      });
  };

  // trainingSubTopics

  const addTrainingSubTopic = ({ dogId, topicId, subTopic }) => {
    subTopic = { ...formatForApi.subtopic(subTopic) };

    let formData = new FormData();
    formData = appendFormdata(formData, subTopic, "training_sub_topic");

    return request()
      .post(
        `/v01/dogs/${dogId}/training_topics/${topicId}/training_sub_topics`,
        formData
      )
      .then((res) => {
        res.data = formatForApp.subtopic(res.data);
        return res;
      });
  };

  const updateTrainingSubTopic = ({ dogId, subTopic, topicId }) => {
    subTopic = { ...formatForApi.subtopic(subTopic) };
    if (!subTopic.exercises) subTopic.exercises = []; // no nulls
    return request()
      .patch(
        `/v01/dogs/${dogId}/training_topics/${topicId}/training_sub_topics/${subTopic.id}`,
        {
          training_sub_topic: {
            done: subTopic.done,
            name: subTopic.name,
            notes: subTopic.notes,
            order_number: subTopic.order_number,
            exercises: subTopic.exercises,
          },
        }
      )
      .then((res) => {
        res.data = formatForApp.subtopic(res.data);
        return res;
      });
  };

  const deleteTrainingSubTopic = ({ dogId, topicId, subTopicId }) => {
    return request()
      .delete(
        `/v01/dogs/${dogId}/training_topics/${topicId}/training_sub_topics/${subTopicId}`
      )
      .then((res) => {
        return res;
      });
  };

  // Exercises
  const addTrainingExercise = ({ dogId, topicId, subTopicId, exercise }) => {
    exercise = { ...formatForApi.exercise(exercise) };
    if (!exercise.video_url) delete exercise.video_url;
    if (!exercise.course_map) delete exercise.course_map;

    let formData = new FormData();
    formData = appendFormdata(formData, exercise, "exercise");
    if (subTopicId === 0) {
      // exercise belongs to topic
      return request()
        .post(
          `/v01/dogs/${dogId}/training_topics/${topicId}/exercises`,
          formData
        )
        .then((res) => {
          res.data = formatForApp.exercise(res.data);
          return res;
        });
    } else {
      // exercise belongs to subtopic
      return request()
        .post(
          `/v01/dogs/${dogId}/training_topics/${topicId}/training_sub_topics/${subTopicId}/exercises`,
          formData
        )
        .then((res) => {
          res.data = formatForApp.exercise(res.data);
          return res;
        });
    }
  };

  const updateTrainingExercise = ({ dogId, topicId, subTopicId, exercise }) => {
    exercise = { ...formatForApi.exercise(exercise) };
    if (!exercise.video_url) delete exercise.video_url;
    if (!exercise.course_map) delete exercise.course_map;

    let formData = new FormData();
    formData = appendFormdata(formData, exercise, "exercise");

    if (subTopicId === 0) {
      // exercise belongs to topic
      return request()
        .patch(
          `/v01/dogs/${dogId}/training_topics/${topicId}/exercises/${exercise.id}`,
          formData
        )
        .then((res) => {
          res.data = formatForApp.exercise(res.data);
          return res;
        });
    } else {
      // exercise belongs to subtopic
      return request()
        .patch(
          `/v01/dogs/${dogId}/training_topics/${topicId}/training_sub_topics/${subTopicId}/exercises/${exercise.id}`,
          formData
        )
        .then((res) => {
          res.data = formatForApp.exercise(res.data);
          return res;
        });
    }
  };

  const deleteTrainingExercise = ({
    dogId,
    topicId,
    subTopicId,
    exerciseId,
  }) => {
    if (subTopicId === 0) {
      // exercise belongs to topic
      return request()
        .delete(
          `/v01/dogs/${dogId}/training_topics/${topicId}/exercises/${exerciseId}`
        )
        .then((res) => {
          return res;
        });
    } else {
      // exercise belongs to subtopic
      return request()
        .delete(
          `/v01/dogs/${dogId}/training_topics/${topicId}/training_sub_topics/${subTopicId}/exercises/${exerciseId}`
        )
        .then((res) => {
          return res;
        });
    }
  };

  const fetchSettings = () =>
    request()
      .get("/v01/settings")
      .then((res) => {
        res.data = {
          trainingFocusCategories: formatForApp.trainingFocusCategories(
            res.data.training_focus_categories
          ),
          trialFaultCategories: formatForApp.trainingFocusCategories(
            res.data.trial_fault_categories
          ),
          trialFaultReasonCategories: formatForApp.trainingFocusCategories(
            res.data.trial_fault_reason_categories
          ),
        };
        return res;
      });

  const fetchLocations = (term) =>
    request().get(`/v01/trial_locations?q=${term}`);

  const fetchJudges = (term) => request().get(`/v01/judges?q=${term}`);

  const fetchCoaches = (term) => request().get(`/v01/coaches?q=${term}`);
  // Other events care persons and veterianarians
  const fetchPersons = (term) => request().get(`/v01/persons?q=${term}`);

  const fetchVeterinarians = (term) => request().get(`/v01/veterinarians?q=${term}`);

  const statistics = {
    fetchTrialListSpeedStats: ({ dog, courseTypeId, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let courseTypeStr = courseTypeId
          ? `&course_type_id=${courseTypeId}`
          : "";
        let filterStr = getTrialFilters(filters);

        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/speed_stats_by_date?${filterStr}${courseTypeStr}`
          )
          .then((res) => {
            res.data = formatForApp.trialListSpeedStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialListCleanRunStats: ({ dog, courseTypeId, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let courseTypeStr = courseTypeId
          ? `&course_type_id=${courseTypeId}`
          : "";
        let filterStr = getTrialFilters(filters);

        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/clean_runs?${filterStr}${courseTypeStr}`
          )
          .then((res) => {
            res.data = formatForApp.trialListCleanRunStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialListFaultStats: ({ dog, courseTypeId, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let courseTypeStr = courseTypeId
          ? `&course_type_id=${courseTypeId}`
          : "";
        let filterStr = getTrialFilters(filters);

        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/trial_faults?${filterStr}${courseTypeStr}`
          )
          .then((res) => {
            res.data = formatForApp.trialListFaultStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialListFaultReasonStats: ({ dog, courseTypeId, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let courseTypeStr = courseTypeId
          ? `&course_type_id=${courseTypeId}`
          : "";

        let filterStr = getTrialFilters(filters);

        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/trial_fault_reasons?${filterStr}${courseTypeStr}`
          )
          .then((res) => {
            res.data = formatForApp.trialListFaultReasonStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialListPointStats: ({ dog, courseTypeId, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let courseTypeStr = courseTypeId
          ? `&course_type_id=${courseTypeId}`
          : "";
        let filterStr = getTrialFilters(filters);

        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/point_stats_by_date?${filterStr}${courseTypeStr}`
          )
          .then((res) => {
            res.data = formatForApp.trialListPointStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialListTimeStats: ({ dog, courseTypeId, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let courseTypeStr = courseTypeId
          ? `&course_type_id=${courseTypeId}`
          : "";
        let filterStr = getTrialFilters(filters);

        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/time_stats_by_date?${filterStr}${courseTypeStr}`
          )
          .then((res) => {
            res.data = formatForApp.trialListTimeStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialListDesignatedTimeStats: ({ dog, courseTypeId, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let courseTypeStr = courseTypeId
          ? `&course_type_id=${courseTypeId}`
          : "";
        let filterStr = getTrialFilters(filters);

        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/designated_time_stats_by_date?${filterStr}${courseTypeStr}`
          )
          .then((res) => {
            res.data = formatForApp.trialListDesignatedTimeStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    trainingFocuses: ({ dog, filters }) => {
      if (dog && !_.isEmpty(dog)) {
        let filterStr = getTrainingFilters(filters);

        return request()
          .get(`/v01/dogs/${dog.id}/statistics/training_focuses?${filterStr}`)
          .then((res) => {
            res.data = formatForApp.trainingFocuses(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialStats: ({ startDate, endDate, dog }) => {
      if (dog && !_.isEmpty(dog)) {
        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/trial_stats?date_start=${startDate}&date_end=${endDate}`
          )
          .then((res) => {
            res.data = formatForApp.trialStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrainingStats: ({ startDate, endDate, dog }) => {
      if (dog && !_.isEmpty(dog)) {
        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/training_stats?date_start=${startDate}&date_end=${endDate}`
          )
          .then((res) => {
            res.data = formatForApp.trainingStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchActivityStats: ({ startDate, endDate, dog }) => {
      if (dog && !_.isEmpty(dog)) {
        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/activity_stats?date_start=${startDate}&date_end=${endDate}`
          )
          .then((res) => {
            res.data = formatForApp.activityStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchTrialPositionStats: ({ startDate, endDate, dog }) => {
      if (dog && !_.isEmpty(dog)) {
        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/trial_position_stats?date_start=${startDate}&date_end=${endDate}`
          )
          .then((res) => {
            res.data = formatForApp.trialPositionStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchCleanRunStats: ({ startDate, endDate, dog }) => {
      if (dog && !_.isEmpty(dog)) {
        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/clean_run_stats?date_start=${startDate}&date_end=${endDate}`
          )
          .then((res) => {
            res.data = formatForApp.cleanRunStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
    fetchSpeedStats: ({ startDate, endDate, dog }) => {
      if (dog && !_.isEmpty(dog)) {
        return request()
          .get(
            `/v01/dogs/${dog.id}/statistics/speed_stats?date_start=${startDate}&date_end=${endDate}`
          )
          .then((res) => {
            res.data = formatForApp.speedStats(res.data);
            return res;
          });
      } else {
        return false;
      }
    },
  };

  // const fetchFeed = ({ dog }) => {
  //   return request()
  //     .get(`/v01/dogs/${dog.id}/feed_items/feed_items_all`)
  //     .then(res => {
  //       res.data = res.data.map(item => formatForApp.feed(item));
  //       res.data = _.sortBy(res.data, t => moment.utc(t.sourceJSON.date)).reverse();
  //       return res;
  //     });
  // };

  // const fetchFeedPaged = ({ dog, page }) => {
  //   return request()
  //     .get(`/v01/dogs/${dog.id}/feed_items/?page=${page}`)
  //     .then(res => {
  //       res.data = res.data.map(item => formatForApp.feed(item));
  //       res.data = _.sortBy(res.data, t => moment.utc(t.sourceJSON.date)).reverse();
  //       return res;
  //     });
  // };

  // const fetchFeedTotal = ({ dog }) => {
  //   return request().get(`/v01/dogs/${dog.id}/feed_items/feed_items_total`);
  // };

  // const shareFeedItem = ({ dog, id }) => {
  //   return request().put(`v01/dogs/${dog.id}/feed_items/${id}`, {
  //     public: true
  //   });
  // };

  const fetchAdminInfo = () => {
    return request()
      .get("v01/usage_stats")
      .then((res) => {
        res.data = formatForApp.adminInfo(res.data);
        return res;
      });
  };

  const getPlans = () => {
    return request().get("v01/saas/subscription_plans");
  };

  const getCurrentPlan = () => {
    return request().get("v01/saas/subscription");
  };

  const subscribe = (customerId, paymentMethodId, planId) => {
    return request().post("v01/saas/subscription", {
      customer_id: customerId,
      payment_method_id: paymentMethodId,
      plan_id: planId,
    });
  };

  const createCustomer = (email, firstName, lastName) => {
    return request().post("v01/saas/customers", {
      email: email,
      first_name: firstName,
      last_name: lastName,
    });
  };

  const createCustomerPortalSession = () => {
    return request().post("v01/saas/subscription/create_portal_session");
  };

  const cancelPlan = (id, token) => {
    return request().post("v01/saas/subscription/cancel", {
      plan_id: id,
      stripe_token: token,
    });
  };

  const reactivatePlan = (id, token) => {
    return request().post("v01/saas/subscription/reactivate", {
      plan_id: id,
      stripe_token: token,
    });
  };

  const updateCreditCard = (token) => {
    return request().post("v01/saas/subscription/update_credit_card", {
      stripe_token: token,
    });
  };

  const getPayments = () => {
    return request().get("v01/saas/payments");
  };

  const fetchCoupon = (code) => {
    return request()
      .get(`/v01/coupons/${code}`)
      .then((res) => {
        res.data = formatForApp.coupon(res.data);
        return res;
      });
  };

  const updateCoupon = (coupon) => {
    coupon = { ...formatForApi.coupon(coupon) };

    let formData = new FormData();
    formData = appendFormdata(formData, coupon, "coupon");

    return request()
      .put(`/v01/coupons/${coupon.code}`, formData)
      .then((res) => {
        res.data = formatForApp.coupon(res.data);
        return res;
      });
  };

  const addCoupon = (coupon) => {
    coupon = { ...formatForApi.coupon(coupon) };

    let formData = new FormData();
    formData = appendFormdata(formData, coupon, "coupon");

    return request()
      .post("/v01/coupons/", formData)
      .then((res) => {
        res.data = formatForApp.coupon(res.data);
        return res;
      });
  };

  const validateCoupon = (id) => {
    if (id) {
      return request().get(`/v01/coupons/${id}/is_valid`);
    } else return false;
  };

  const fetchCouponAvailable = (id) => {
    if (id) {
      return request().get(`/v01/coupons/${id}/is_available`);
    } else return false;
  };

  const fetchOrganizations = () => {
    return request()
      .get("/v01/organizations")
      .then((res) => {
        res.data = res.data.map((organization) =>
          formatForApp.organization(organization)
        );
        return res;
      });
  };

  const fetchSizeClasses = (orgId) => {
    return request()
      .get(`/v01/organizations/${orgId}/size_classes`)
      .then((res) => {
        let data = [
          {
            orgId: orgId,
            data: res.data.map((sizeClass) =>
              formatForApp.sizeClass(sizeClass)
            ),
          },
        ];
        res.data = data;
        return res;
      });
  };

  const fetchCourseTypes = (orgId) => {
    return request()
      .get(`/v01/organizations/${orgId}/course_types`)
      .then((res) => {
        res.data = res.data.map((courseType) =>
          formatForApp.courseType(courseType)
        );
        return res;
      });
  };

  const postErrorLog = (errorLog) => {
    let formData = new FormData();
    formData = appendFormdata(formData, errorLog, "error_log");

    return request()
      .post("/v01/error_logs/", formData)
      .then((res) => {
        return res;
      });
  };

  const fetchApiClientVersion = () => {
    return request().get("/v01/client_version");
  };

  const fetchJudgesFilter = ({ dog }) => {
    if (dog && !_.isEmpty(dog)) {
      return request()
        .get(`/v01/dogs/${dog.id}/trial_filters/judges`)
        .then((res) => {
          res.data = res.data.map((judge) => formatForApp.judge(judge));
          return res;
        });
    } else {
      return false;
    }
  };

  const fetchTrialLocationsFilter = ({ dog }) => {
    if (dog && !_.isEmpty(dog)) {
      return request()
        .get(`/v01/dogs/${dog.id}/trial_filters/locations`)
        .then((res) => {
          res.data = res.data.map((trialLocation) =>
            formatForApp.trialLocation(trialLocation)
          );
          return res;
        });
    } else {
      return false;
    }
  };

  const fetchCoachesFilter = ({ dog }) => {
    if (dog && !_.isEmpty(dog)) {
      return request()
        .get(`/v01/dogs/${dog.id}/training_filters/coaches`)
        .then((res) => {
          res.data = res.data.map((coach) => formatForApp.coach(coach));
          return res;
        });
    } else {
      return false;
    }
  };

  return {
    headers,
    login,
    logout,
    signup,
    returnPassword,
    fetchUser,
    updateUser,
    saveSettings,
    fetchDogs,
    addDog,
    updateDog,
    deleteDog,
    fetchDog,
    updateDogTrialSizeClass,
    // fetchTrainings,
    fetchTrainingsFiltered,
    fetchTrainingsPaged,
    fetchTrainingsPagesCount,
    fetchTrainingsCount,
    addTraining,
    updateTraining,
    deleteTraining,
    // fetchTrials,
    fetchTrialsFiltered,
    fetchTrialsPaged,
    fetchTrialsPagesCount,
    fetchTrialsCount,
    fetchSettings,
    addTrial,
    updateTrial,
    deleteTrial,
    // Other events
    fetchOtherEventsFiltered,
    addOtherEvent,
    updateOtherEvent,
    deleteOtherEvent,
    // Training topics
    fetchTrainingTopics,
    fetchSharedTopics,
    shareTopic,
    unShareTopic,
    getSharedTopic,
    copySharedTopic,
    addTrainingTopic,
    addTrainingTopicForm,
    updateTrainingTopic,
    deleteTrainingTopic,
    copyTrainingTopic,
    addTrainingSubTopic,
    updateTrainingSubTopic,
    deleteTrainingSubTopic,
    addTrainingExercise,
    updateTrainingExercise,
    deleteTrainingExercise,
    // Locations and people
    fetchLocations,
    fetchCoaches,
    fetchJudges,
    fetchPersons,
    fetchVeterinarians,
    statistics,
    fetchAdminInfo,
    getPlans,
    getCurrentPlan,
    subscribe,
    cancelPlan,
    reactivatePlan,
    updateCreditCard,
    getPayments,
    fetchCoupon,
    updateCoupon,
    addCoupon,
    validateCoupon,
    fetchCouponAvailable,
    fetchOrganizations,
    fetchSizeClasses,
    fetchCourseTypes,
    postErrorLog,
    fetchApiClientVersion,
    fetchJudgesFilter,
    fetchTrialLocationsFilter,
    fetchCoachesFilter,
    createCustomer,
    createCustomerPortalSession,
  };
};

export default Api;

function appendFormdata(FormData, data, prefix) {
  prefix = prefix || "";

  if (
    data &&
    data.constructor &&
    data.constructor.name &&
    data.constructor.name === "File"
  ) {
    if (data) FormData.append(prefix, data);
  } else if (
    data &&
    data.constructor &&
    data.constructor.name &&
    data.constructor.name === "Array"
  ) {
    // eslint-disable-next-line no-unused-vars
    _.each(data, function (value, key) {
      FormData = appendFormdata(FormData, value, `${prefix}[]`);
    });
  } else if (typeof data === "object") {
    _.each(data, function (value, key) {
      FormData = appendFormdata(FormData, value, `${prefix}[${key}]`);
    });
  } else {
    FormData.append(prefix, data);
  }
  return FormData;
}

function getTrialFilters(filters) {
  let combinedFilters = [];
  if (filters && filters.judgesFilter && filters.judgesFilter.length > 0) {
    combinedFilters.push(
      "judges[]=" + filters.judgesFilter.map((j) => j.value).join("&judges[]=")
    );
  }
  if (
    filters &&
    filters.trialLocationsFilter &&
    filters.trialLocationsFilter.length > 0
  ) {
    combinedFilters.push(
      "trial_locations[]=" +
        filters.trialLocationsFilter
          .map((l) => l.value)
          .join("&trial_locations[]=")
    );
  }
  if (filters && filters.datesFilter && filters.datesFilter.length > 0) {
    if (filters.datesFilter[0] && filters.datesFilter[1]) {
      combinedFilters.push(
        `date_start=${moment(filters.datesFilter[0]).format(
          "YYYY-MM-DD"
        )}&date_end=${moment(filters.datesFilter[1]).format("YYYY-MM-DD")}`
      );
    }
  }

  if (filters && filters.organizationsFilter) {
    combinedFilters.push(`organization_id=${filters.organizationsFilter}`);
  }

  if (
    filters &&
    filters.sizeClassesFilter &&
    filters.sizeClassesFilter.length > 0
  ) {
    combinedFilters.push(
      "size_class_ids[]=" + filters.sizeClassesFilter.join("&size_class_ids[]=")
    );
  }

  if (filters && filters.resultsFilter) {
    combinedFilters.push(`result=${filters.resultsFilter}`);
  }

  return combinedFilters.join("&");
}

function getTrainingFilters(filters) {
  let combinedFilters = [];
  if (filters && filters.coachesFilter && filters.coachesFilter.length > 0) {
    combinedFilters.push(
      "coaches[]=" +
        filters.coachesFilter.map((c) => c.value).join("&coaches[]=")
    );
  }

  if (filters && filters.datesFilter && filters.datesFilter.length > 0) {
    if (filters.datesFilter[0] && filters.datesFilter[1]) {
      combinedFilters.push(
        `date_start=${moment(filters.datesFilter[0]).format(
          "YYYY-MM-DD"
        )}&date_end=${moment(filters.datesFilter[1]).format("YYYY-MM-DD")}`
      );
    }
  }

  if (filters && filters.trainingTypeFilter) {
    combinedFilters.push(`training_type=${filters.trainingTypeFilter}`);
  }

  return combinedFilters.join("&");
}

function getOtherEventFilters(filters) {
  let combinedFilters = [];

  if (filters && filters.datesFilter && filters.datesFilter.length > 0) {
    if (filters.datesFilter[0] && filters.datesFilter[1]) {
      combinedFilters.push(
        `date_start=${moment(filters.datesFilter[0]).format(
          "YYYY-MM-DD"
        )}&date_end=${moment(filters.datesFilter[1]).format("YYYY-MM-DD")}`
      );
    }
  }

  return combinedFilters.join("&");
}
