import _ from "lodash";
import moment from "moment";

export const formatForApp = {
  map: {
    user: {
      id: "id",
      email: "email",
      photo: "photo",
      param_id: "paramId",
      first_name: "firstName",
      last_name: "lastName",
      terms_of_service: "termsAgreed",
      allow_marketing_emails: "allowEmails",
      language: "language",
      created_at: "createdAt",
      updated_at: "upadtedAt",
      team_name: "team",
      photo_url: "photo",
      photo_variants: "photoVariants",
      units: "units",
    },
    dog: {
      id: "id",
      param_id: "paramId",
      name: "name",
      description: "description",
      official_name: "officialName",
      height_cm: "height",
      competition_class: "competitionClass",
      sex: "sex",
      race: "race",
      birthdate: "dayOfBirth",
      photo_id: "photoId",
      photo_variants: "photoVariants",
      photo: "photoRaw",
      user_id: "userId",
      size_classes: "sizeClasses",
      course_types: "courseTypes",
      created_at: "createdAt",
      updated_at: "updatedAt",
    },
    event: {
      id: "id",
      dog_id: "dogId",
      start_date: "date",
      end_date: "end",
      event_type: "eventType",
      person: "person",
      veterinarian: "veterinarian",
      photo_variants: "eventImageVariants",
      notes: "notes",
      created_at: "createdAt",
      updated_at: "updatedAt",
    },
    training: {
      id: "id",
      dog_id: "dogId",
      preview_photo_url: "previewPhotoUrl",
      training_type: "trainingType",
      training_focuses: "focuses",
      date: "date",
      notes: "notes",
      video_url: "videoUrl",
      rating: "rating",
      course_map_variants: "courseMapVariants",
      created_at: "createdAt",
      updated_at: "updatedAt",
      coach: "coach",
      exercise_id: "exerciseId",
    },
    trainingFocusCategories: {
      name: "name",
      group_name: "groupName",
    },
    focus: {
      id: "id",
      dog_id: "dogId",
      value: "value",
      date: "date",
      category: "category",
      category_key: "categoryKey",
      created_at: "createdAt",
      updated_at: "updatedAt",
    },
    fault: {
      id: "id",
      dog_id: "dogId",
      value: "value",
      date: "date",
      category_key: "categoryKey",
      created_at: "createdAt",
      updated_at: "updatedAt",
    },
    faultReason: {
      id: "id",
      dog_id: "dogId",
      value: "value",
      date: "date",
      category_key: "categoryKey",
      created_at: "createdAt",
      updated_at: "updatedAt",
    },
    trial: {
      id: "id",
      dog_id: "dogId",
      date: "date",
      judge: "judge",
      result: "result",
      speed: "speed",
      winning_speed: "winningSpeed",
      time_faults: "timeFaults",
      course_faults: "courseFaults",
      trial_fault_reasons: "faultReasons",
      notes: "notes",
      video_url: "videoUrl",
      certs: "certs",
      position: "placement",
      course_map_url: "courseMapUrl",
      course_map_variants: "courseMapVariants",
      trial_location: "location",
      trial_faults: "faults",
      course_length: "courseLength",
      time: "trialTime",
      winning_time: "winningTime",
      opening_points: "openingPoints",
      closing_points: "closingPoints",
      winning_points: "winningPoints",
      total_points: "totalPoints",
      designated_time: "designatedTime",
      time_difference: "timeDifference",
      clean_run: "cleanRun",
    },
    topic: {
      created_at: "createdAt",
      dog_id: "dogId",
      done: "done",
      id: "id",
      name: "name",
      order_number: "orderNumber",
      training_sub_topics: "trainingSubTopics",
      training_focuses: "trainingFocuses",
      exercises: "exercises",
      training_topic_share: "share",
      updated_at: "updatedAt",
      training_topic_id: "trainingTopicId",
    },
    sharedTopic: {
      id: "id",
      guid: "guid",
      created_at: "createdAt",
      training_topic_name: "trainingTopic",
      name: "dogName",
      email: "userEmail",
    },
    subtopic: {
      done: "done",
      id: "id",
      name: "name",
      order_number: "orderNumber",
      exercises: "exercises",
      updated_at: "updatedAt",
      created_at: "createdAt",
      training_topic_id: "trainingTopicId",
      notes: "notes",
    },
    exercise: {
      id: "id",
      name: "name",
      order_number: "orderNumber",
      trained_exercises: "trainedExercises",
      notes: "notes",
      done: "done",
      course_map_variants: "courseMapVariants",
      video_url: "videoUrl",
      updated_at: "updatedAt",
      created_at: "createdAt",
      trained_date: "trainedDate",
    },
    adminInfo: {
      users_count: "usersCount",
      dogs_count: "dogsCount",
      trainings_count: "trainingsCount",
      trials_count: "trialsCount",
    },
    coupon: {
      created_at: "createdAt",
      id: "id",
      code: "code",
      valid_until: "validUntil",
      discount_months: "discountMonths",
      coach: "coach",
      used_date: "usedDate",
      user_id: "userId",
      updated_at: "updatedAt",
    },
    trial_stats: {
      trials_count: "trialsCount",
      trials_agility: "trialsAgility",
      trials_jumping: "trialsJumping",
      trials_speedstakes: "trialsSpeedstakes",
      trials_gamblers: "trialsGamblers",
      trials_snooker: "trialsSnooker",
      trials_time_gamble: "trialsTimeGamble",
      trials_power_and_speed: "trialsPowerAndSpeed",
      trials_FAST: "trialsFAST",
      trials_time_2_beat: "trialsTime2Beat",
      trials_snakes_and_ladders: "trialsSnakesAndLadders",
      trials_time_fault_and_out: "trialsTimeFaultAndOut",
      trials_dog_jumping: "trialsDogJumping",
    },
    training_stats: {
      trainings_count: "trainingsCount",
      trainings_coached: "trainingsCoached",
      trainings_independent: "trainingsIndependent",
    },
    acivity_stats: {
      trainings_per_month: "trainingsPerMonth",
      trials_per_month: "trialsPerMonth",
    },
    trial_position_stats: {
      trial_positions: "trialPositions",
    },
    clean_run_stats: {
      trials_agility: "cleanRunsAgility",
      trials_jumping: "cleanRunsJumping",
      trials_speedstakes: "cleanRunsSpeedstakes",
      trials_power_and_speed: "cleanRunsPowerAndSpeed",
      trials_time_2_beat: "cleanRunsTime2Beat",
      trials_snakes_and_ladders: "cleanRunsSnakesAndLadders",
      trials_time_fault_and_out: "cleanRunsTimeFaultAndOut",
      trials_dog_jumping: "cleanRunsDogJumping",
      trials_total: "cleanRunsTotal",
    },
    speed_stats: {
      speed_agility: "speedAgility",
      speed_jumping: "speedJumping",
      speed_speedstakes: "speedSpeedstakes",
      speed_dog_jumping: "speedDogJumping",
      speed_all: "speedAll",
    },
    trial_list_speed_stats: {
      date: "date",
      speed: "speed",
      winning_speed: "winningSpeed",
    },
    trial_list_clean_run_stats: {
      clean_run_count: "cleanRunCount",
      run_count: "runCount",
      percentage: "percentage",
    },
    trial_list_point_stats: {
      date: "date",
      points: "points",
      winning_points: "winningPoints",
    },
    trial_list_time_stats: {
      date: "date",
      time: "time",
      winning_time: "winningTime",
    },
    trial_list_designated_time_stats: {
      date: "date",
      time: "time",
      winning_time: "designatedTime",
    },
    organization: {
      id: "id",
      name: "name",
    },
    sizeClass: {
      id: "id",
      name: "name",
      organization_id: "organizationId",
    },
    courseType: {
      id: "id",
      name: "name",
    },
    judge: {
      id: "id",
      name: "name",
    },
    trialLocation: {
      id: "id",
      name: "name",
    },
    coach: {
      id: "id",
      name: "name",
    },
    person: {
      id: "id",
      name: "name",
    },
    veterinarian: {
      id: "id",
      name: "name",
    },
  },

  user(user) {
    const covertMap = this.map;
    const formattedUser = {};

    _.each(user, function(value, key) {
      key = covertMap.user[key] || key;
      formattedUser[key] = value;
    });

    formattedUser.photo = user.photo_variants && user.photo_variants.hd;

    return formattedUser;
  },

  dog(dog) {
    const covertMap = this.map;
    const formattedDog = {};
    let that = this;
    _.each(dog, function(value, key) {
      key = covertMap.dog[key] || key;
      if (Array.isArray(value)) {
        let formattedItem = [];
        if (key === "organizations") {
          _.each(value, function(value) {
            formattedItem.push(that.format(value, that.map.organization));
          });
          formattedDog[key] = formattedItem;
        } else if (key === "sizeClasses") {
          _.each(value, function(value) {
            formattedItem.push(that.format(value, that.map.sizeClass));
          });
          formattedDog[key] = formattedItem;
        } else {
          formattedDog[key] = value;
        }
      } else {
        formattedDog[key] = value;
      }
    });

    formattedDog.photo =
      formattedDog.photoVariants && formattedDog.photoVariants.hd;

    return formattedDog;
  },

  trainingFocusCategories(categoriesFromAPI) {
    // Cast from object to array
    const parsedCategoriesFromAPI = _.map(categoriesFromAPI, (i, id) => ({
      categoryKey: id,
      name: i.name,
      groupName: i.group_name,
      key: i.group_name ? `${i.name}_${i.group_name}` : i.name,
      count: 0,
      order: i.order,
    }));

    return _.sortBy(parsedCategoriesFromAPI, "order");
  },

  /*
  {
    id:
    dogId:
    value:
    date:
    createdAt:
    updatedAt:
    name:
    groupName:
  }
  */
  focus(focus) {
    focus = this.format(focus, this.map.focus);
    focus.name = focus.category.name;
    focus.groupName = focus.category.group_name;
    focus.key = focus.groupName
      ? `${focus.name}_${focus.groupName}`
      : focus.name;
    return focus;
  },

  fault(fault) {
    fault = this.format(fault, this.map.fault);
    fault.name = fault.category.name;
    fault.groupName = fault.category.group_name;
    fault.key = fault.groupName
      ? `${fault.name}_${fault.groupName}`
      : fault.name;
    fault.count = fault.count ? fault.count : 0;
    return fault;
  },

  /*
  {
    id:
    dogId:
    trainingType:
    date:
    notes:
    videoUrl:
    previewPhotoUrl:
    rating:
    createdAt:
    updatedAt:
    focus: []
  }
  */
  training(training) {
    const coach = { ...training.coach };
    training = this.format(training, this.map.training);
    training.coach = coach.name;
    training.coachId = coach.id;
    training.focuses = training.focuses.map((focus) =>
      this.focus(focus, this.map.focus)
    );

    training.courseMap =
      training.courseMapVariants && training.courseMapVariants.hd;

    return training;
  },

  trial(trial) {
    const location = { ...trial.trial_location };
    const judge = { ...trial.judge };
    const organization = { ...trial.organization };
    const sizeClass = { ...trial.size_class };
    const courseType = { ...trial.course_type };
    trial = this.format(trial, this.map.trial);
    trial.location = location.name;
    trial.locationId = location.id;
    trial.judge = judge.name;
    trial.judgeId = judge.id;
    trial.organizationId = organization.id;
    trial.sizeClassId = sizeClass.id;
    trial.courseTypeId = courseType.id;
    trial.sizeClass = sizeClass.name;
    trial.courseType = courseType.name;
    trial.organization = organization.name;

    trial.result !== null
      ? (trial.result = trial.result.toString())
      : (trial.result = null);

    // If same fault is added multiple times, then
    // API sends fault multiple times
    trial.faults = trial.faults.map((fault) => {
      fault.count = trial.faults.reduce((sum, f) => {
        return f.category_key === fault.category_key ? sum + 1 : sum;
      }, 0);
      return fault;
    });
    trial.faults = _.uniqBy(trial.faults, "category_key");

    trial.faults = trial.faults
      ? trial.faults.map((fault) => {
        return this.fault(fault, this.map.fault);
      })
      : [];
    trial.faultReasons = trial.faultReasons
      ? trial.faultReasons.map((faultReason) =>
        this.fault(faultReason, this.map.faultReason)
      )
      : [];
    if (!trial.faultReasons) trial.faultReasons = [];

    trial.courseMap = trial.courseMapVariants && trial.courseMapVariants.hd;
    return trial;
  },

  event(event) {
    const person = { ...event.person };
    const veterinarian = { ...event.veterinarian };
    event = this.format(event, this.map.event);
    event.person = person.name;
    event.personId = person.id;
    event.veterinarian = veterinarian.name;
    event.veterinarianId = veterinarian.id;

    event.eventImage =
      event.eventImageVariants && event.eventImageVariants.hd;

    return event;
  },

  topic(topic) {
    topic = this.format(topic, this.map.topic);
    topic.trainingFocuses = topic.trainingFocuses.map((focus) =>
      this.focus(focus, this.map.focus)
    );
    topic.trainingSubTopics = topic.trainingSubTopics.map((subtopic) =>
      this.subtopic(subtopic, this.map.subtopic)
    );
    topic.exercises = topic.exercises.map((exercise) =>
      this.exercise(exercise, this.map.exercise)
    );

    return topic;
  },
  sharedTopic(topic) {
    topic = this.format(topic, this.map.sharedTopic);
    return topic;
  },
  subtopic(subtopic) {
    subtopic = this.format(subtopic, this.map.subtopic);
    if (subtopic.exercises) {
      subtopic.exercises = subtopic.exercises.map((exercise) =>
        this.exercise(exercise, this.map.exercise)
      );
    }

    return subtopic;
  },

  exercise(exercise) {
    exercise = this.format(exercise, this.map.exercise);

    exercise.courseMap =
      exercise.courseMapVariants && exercise.courseMapVariants.hd;

    // Formatting the list of trained exercises.
    //if (exercise.trainedExercises) {
    exercise.trainedExercises = this.trainedExercises(
      exercise.trainedExercises
    );
    //}

    return exercise;
  },

  trainedExercises(trainedExercises) {
    trainedExercises = trainedExercises.map((trainedExercise) => {
      let training = this.format(trainedExercise.training, this.map.training);
      return {
        training,
        id: trainedExercise.id,
        trainedDate: trainedExercise.trained_date,
      };
    });
    // TODO: coach_id and organization_id?
    return trainedExercises;
  },

  trialStats(trials) {
    return this.format(trials, this.map.trial_stats);
  },

  trainingStats(trainings) {
    return this.format(trainings, this.map.training_stats);
  },

  activityStats(activities) {
    return this.format(activities, this.map.acivity_stats);
  },

  trialPositionStats(positions) {
    return this.format(positions, this.map.trial_position_stats);
  },

  cleanRunStats(runs) {
    return this.format(runs, this.map.clean_run_stats);
  },

  speedStats(speeds) {
    return this.format(speeds, this.map.speed_stats);
  },

  trialListSpeedStats(speeds) {
    let formattedSpeeds = speeds
      ? speeds.map((speed) =>
        this.format(speed, this.map.trial_list_speed_stats)
      )
      : [];

    let rawSpeedStatistics = { dates: [], speed: [], winningSpeed: [] };

    formattedSpeeds.forEach((trial) => {
      if (trial.speed) {
        rawSpeedStatistics.dates.push(moment(trial.date).format("YYYY-MM-DD"));
        rawSpeedStatistics.speed.push(parseFloat(trial.speed));
        rawSpeedStatistics.winningSpeed.push(parseFloat(trial.winningSpeed));
      }
    });
    return rawSpeedStatistics;
  },

  trialListCleanRunStats(data) {
    const d = {};
    _.map(
      _(data)
        .toPairs()
        .sortBy(0)
        .fromPairs()
        .value(),
      (item, index) => {
        d[index] = item.percentage;
      }
    );

    //Let's count the average for real
    let runCount = _(data).reduce((a, b) => a + (b["run_count"] || 0), 0);
    let cleanCount = _(data).reduce(
      (a, b) => a + (b["clean_run_count"] || 0),
      0
    );
    let cleanRunAverage = ((cleanCount * 1.0) / (runCount * 1.0)) * 100;

    let cleanRunStatistics = {
      average: cleanRunAverage,
      data: {
        labels: _.keys(d),
        datasets: [{ data: _.values(d) }],
      },
    };
    return cleanRunStatistics;
  },

  trialListFaultStats(data) {
    let d = _.uniqBy(data, "category_key");
    d = d.map((i) => {
      const value = data.reduce((sum, j) => {
        return i.category_key === j.category_key ? sum + 1 : sum;
      }, 0);

      const label = i.category.group_name
        ? i.category.group_name + ": " + i.value
        : i.value;

      return { label, value };
    });
    d = d.sort((a, b) => b.value - a.value);
    d = _.take(d, 6);

    let faultStatistics = {
      labels: d.map((i) => i.label),
      datasets: [{ data: d.map((i) => i.value) }],
    };
    return faultStatistics;
  },

  trialListFaultReasonStats(data) {
    let d = _.uniqBy(data, "category_key");
    d = d.map((i) => {
      const value = data.reduce((sum, j) => {
        return i.category_key === j.category_key ? sum + 1 : sum;
      }, 0);

      const label =
        i.category.group_name && i.category.group_name !== "other-skills"
          ? i.category.group_name + ": " + i.value
          : i.value;

      return { label, value };
    });
    d = d.sort((a, b) => b.value - a.value);
    d = _.take(d, 6);

    let faultReasonStatistics = {
      labels: d.map((i) => i.label),
      datasets: [{ data: d.map((i) => i.value) }],
    };
    return faultReasonStatistics;
  },

  trialListPointStats(points) {
    let formattedPoints = points
      ? points.map((point) =>
        this.format(point, this.map.trial_list_point_stats)
      )
      : [];

    let rawPointStatistics = {
      dates: [],
      points: [],
      winningPoints: [],
    };

    formattedPoints.forEach((trial) => {
      if (trial.points) {
        rawPointStatistics.dates.push(moment(trial.date).format("YYYY-MM-DD"));
        rawPointStatistics.points.push(parseFloat(trial.points));
        rawPointStatistics.winningPoints.push(parseFloat(trial.winningPoints));
      }
    });
    return rawPointStatistics;
  },

  trialListTimeStats(times) {
    let formattedTimes = times
      ? times.map((time) => this.format(time, this.map.trial_list_time_stats))
      : [];

    let rawTimeStatistics = {
      dates: [],
      times: [],
      winningTimes: [],
    };

    formattedTimes.forEach((trial) => {
      rawTimeStatistics.dates.push(moment(trial.date).format("YYYY-MM-DD"));
      rawTimeStatistics.times.push(parseFloat(trial.time));
      rawTimeStatistics.winningTimes.push(parseFloat(trial.winningTime));
    });
    return rawTimeStatistics;
  },

  trialListDesignatedTimeStats(times) {
    let formattedTimes = times
      ? times.map((time) =>
        this.format(time, this.map.trial_list_designated_time_stats)
      )
      : [];

    let rawTimeStatistics = {
      dates: [],
      times: [],
      designatedTimes: [],
    };

    formattedTimes.forEach((trial) => {
      rawTimeStatistics.dates.push(moment(trial.date).format("YYYY-MM-DD"));
      rawTimeStatistics.times.push(parseFloat(trial.time));
      rawTimeStatistics.designatedTimes.push(parseFloat(trial.designatedTime));
    });
    return rawTimeStatistics;
  },

  trainingFocuses(data) {
    let d = _.uniqBy(data, "category_key");
    d = d.map((i) => {
      const value = data.reduce((sum, j) => {
        return i.category_key === j.category_key ? sum + 1 : sum;
      }, 0);

      const label = i.category.group_name
        ? i.category.group_name + ": " + i.value
        : i.value;

      return { label, value };
    });
    d = d.sort((a, b) => b.value - a.value);
    d = _.take(d, 6);

    let focusStatistics = {
      labels: d.map((i) => i.label),
      datasets: [{ data: d.map((i) => i.value) }],
    };
    return focusStatistics;
  },

  adminInfo(adminInfo) {
    return this.format(adminInfo, this.map.adminInfo);
  },

  coupon(coupon) {
    return this.format(coupon, this.map.coupon);
  },

  organization(organization) {
    return this.format(organization, this.map.organization);
  },

  sizeClass(sizeClass) {
    return this.format(sizeClass, this.map.sizeClass);
  },

  courseType(courseType) {
    return this.format(courseType, this.map.courseType);
  },

  judge(judge) {
    return this.format(judge, this.map.judge);
  },
  trialLocation(location) {
    return this.format(location, this.map.trialLocation);
  },
  coach(coach) {
    return this.format(coach, this.map.coach);
  },
  person(person) {
    return this.format(person, this.map.person);
  },
  veterinarian(vetenarian) {
    return this.format(vetenarian, this.map.veterinarian);
  },

  format(item, map) {
    const formattedItem = {};

    _.each(item, function(value, key) {
      key = map[key] || key;
      formattedItem[key] = value;
    });

    return formattedItem;
  },
};
