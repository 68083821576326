import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import { Redirect } from "react-router-dom";
import confirm from "../../components/Confirm";
import PaywallNotification from "../../containers/App/PaywallNotification";
import { castToNestedFocusArray } from "../../helpers/Transformer";
import { updateTrainingExercise } from "../../actions";
import { AppContent, Card, RoundButton, Image, Stars } from "../../common";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ExerciseView extends Component {
  getPremium = (e) => {
    const { t } = this.props;
    e.stopPropagation();
    e.preventDefault();
    console.log("Get premium to unlock this feature!");
    confirm(t("subscription.noPlanYet"), {
      title: t("paywall.getPremium"),
      ok: t("common.ok"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.props.history.push("/subscription");
      },
      () => {}
    );
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleMoveToTrainingDiary = (id, e) => {
    e.stopPropagation();
    console.log("Going to move exercise id: " + id + " to training diary");
    this.props.history.push(`/training-diary/add/${id}`);
  };

  handleTrainingView = (id, e) => {
    e.stopPropagation();
    console.log("Going to view training " + id);
    this.props.history.push(`/training-diary/${id}`);
  };

  render() {
    const { t, exercise, hasActivePlan } = this.props;

    if (!this.props.topic) return <Redirect to="/training-topics" />;
    if (!exercise) { // deleted
      const { topicid, subtopicid } = this.props.match.params;
      if (!subtopicid) {
        return <Redirect to={`/training-topic/${topicid}`} />;
      } else {
        return <Redirect to={`/training-topic/${topicid}/${subtopicid}`} />;
      }
    }

    let topicName = this.props.topic.name;
    if (this.props.subtopic) {
      topicName = topicName + " | " + this.props.subtopic.name;
    }

    return (
      <AppContent size="medium">
        <button className="trial-back-button" onClick={this.handleGoBack}>
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <div className="d-flex justify-content-end">
          <p className="py-1" />
          {/* <button className="btn btn-info">{t("common.share")}!</button> */}
        </div>
        <Card>
          <h1 className="text-center">{exercise.name}</h1>
          <h2>{topicName}</h2>
          <p>
            <b>{t("training.focuses")}: </b>
            <span>
              {castToNestedFocusArray(this.props.topic.trainingFocuses).map(
                (focus, i, arr) => (
                  <span key={focus.key}>
                    {t(`misc.${focus.name}`)}
                    {focus.children && focus.children.length > 0 && (
                      <span>
                        :{" "}
                        {focus.children
                          .map((f) => t(`misc.${f.name}`))
                          .join(", ")}
                      </span>
                    )}
                    {arr.length - 1 === i ? <span /> : <span>, </span>}
                  </span>
                )
              )}
            </span>
          </p>
          <p />
          <h3>{t("training.notes")}</h3>
          <p className="multiLine">{exercise.notes}</p>
          {exercise.courseMapVariants &&
            exercise.courseMapVariants.original && (
            <h3>{t("training.courseMap")}</h3>
          )}
          {exercise.courseMapVariants && exercise.courseMapVariants.original && (
            <div className="d-flex justify-content-center">
              <div className="d-block h-auto">
                {exercise.courseMapVariants.original.indexOf(".pdf") !== -1 && (
                  <Document file={exercise.courseMapVariants.original}>
                    <Page pageNumber={1} />
                  </Document>
                )}
                {exercise.courseMapVariants.original.indexOf(".pdf") === -1 && (
                  <Image src={exercise.courseMapVariants.hd} />
                )}
              </div>
            </div>
          )}
          {/* <div className="d-flex justify-content-center">
            <Image src={require("../../images/running-dog.png").default} />
          </div> */}
          {exercise.videoUrl && <h3>{t("training.videoUrl")}</h3>}
          {exercise.videoUrl &&
            exercise.videoUrl.indexOf("instagram") === -1 && (
            <ReactPlayer url={exercise.videoUrl} controls width="100%" />
          )}
          {exercise.videoUrl &&
            exercise.videoUrl.indexOf("instagram") !== -1 && (
            <InstagramEmbed url={exercise.videoUrl} />
          )}
          <p />
          <div className="d-flex justify-content-center">
            {hasActivePlan ? (
              <button
                className="btn btn-outline-success"
                onClick={(e) => this.handleMoveToTrainingDiary(exercise.id, e)}
              >
                {t("topics.moveToTraining")}
              </button>
            ) : (
              <button
                className="btn btn-outline-secondary"
                onClick={(e) => this.getPremium(e)}
              >
                {t("topics.moveToTraining")}
              </button>
            )}
          </div>
          <p />
          {this.renderTrainedExercises()}
        </Card>

        {hasActivePlan ? (
          <RoundButton
            atCorner
            size="large"
            icon={"edit"}
            onClick={(e) => this.handleExerciseEdit(exercise.id, e)}
          />
        ) : (
          <PaywallNotification
            text="subscription.noPlanYet"
            button="paywall.getPremium"
          />
        )}
      </AppContent>
    );
  }

  renderTrainedExercises() {
    const { t, exercise } = this.props;

    return (
      <React.Fragment>
        {exercise.trainedExercises && exercise.trainedExercises.length > 0 ? (
          <div>
            <h3 className="m-0">{t("topics.trained")}:</h3>
            <ul className="list list--simple">
              {exercise.trainedExercises
                .sort(
                  (a, b) =>
                    new Date(b.training.date) - new Date(a.training.date)
                )
                .map((trained, index) => {
                  return (
                    <li key={index}>
                      <button
                        className="btn--link"
                        onClick={(e) =>
                          this.handleTrainingView(trained.training.id, e)
                        }
                      >
                        <span className="text-info">
                          {moment(trained.training.date).format("ll")}
                        </span>
                      </button>
                      {trained.training.rating ? (
                        <Stars value={trained.training.rating} size="small" />
                      ) : (
                        <p />
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  }

  handleExerciseEdit = (id, e) => {
    e.stopPropagation();
    let topicId = this.props.topic.id;
    const { subtopicid } = this.props.match.params;

    if (!subtopicid) {
      console.log(
        "Going to edit exercise id: " + id + " of topic ID: " + topicId
      );
      this.props.history.push(`/training-topic/${topicId}/edit/${id}`);
    } else {
      console.log(
        "Going to edit exercise id: " +
          id +
          " of topic ID: " +
          topicId +
          " and subtopic ID: " +
          subtopicid
      );
      this.props.history.push(
        `/training-topic/${topicId}/${subtopicid}/edit/${id}`
      );
    }
  };
}

ExerciseView.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  auth_token: PropTypes.any,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  exercise: PropTypes.object,
  number: PropTypes.number,
  hasActivePlan: PropTypes.bool
};

const mapStateToProps = ({ activeDog, topics, auth, subscriptions }, props) => {
  // Have to find the exercise if it exists from either the topic or the subtopic.
  const { topicid, subtopicid, id } = props.match.params;
  let topic = topics.topics.find((t) => t.id === Number(topicid));
  let subtopic = null;
  let exercise = null;
  let number = 1;
  if (topic && subtopicid) {
    // Exercise belongs to subtopic.
    subtopic = topic.trainingSubTopics.find(
      (st) => st.id === Number(subtopicid)
    );
    if (subtopic.exercises) {
      exercise = subtopic.exercises.find((ex) => ex.id === Number(id));
      number = subtopic.exercises.length + 1;
    }
  } else if (topic && !subtopicid) {
    // Exercise belongs to topic.
    if (topic && topic.exercises) {
      exercise = topic.exercises.find((ex) => ex.id === Number(id));
      number = topic.exercises.length + 1;
    }
  }

  return {
    dog: activeDog,
    topic,
    subtopic,
    exercise,
    number,
    saving: topics.saving,
    error: topics.error,
    auth_token: auth.authToken,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};

const ExerciseViewWithTranslate = withTranslation()(ExerciseView);
export default connect(mapStateToProps, { updateTrainingExercise })(
  ExerciseViewWithTranslate
);
