import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { facebook } from "../config/env";

class FacebookAuth extends Component {
  FBAuth = null;

  componentDidMount() {
    this.inject();
  }

  inject = () => {
    const inject = document.createElement("script");

    inject.src = "https://connect.facebook.net/en_US/sdk.js";
    inject.onload = () => this.initialize();

    document.body.appendChild(inject);
  };

  initialize = () => {
    window.FB.init({
      appId: `${facebook.id}`,
      status: false,
      autoLogAppEvents: true,
      xfbml: true,
      version: "v13.0",
    });

    this.setAuthService();
  };

  setAuthService = () => {
    this.FBAuth = window.FB;
  };

  signIn = () => {
    if (!this.FBAuth) return;

    let fbAuth = this.FBAuth;

    fbAuth.getLoginStatus((response) => {
      if (response.status === "connected") {
        this.handleSuccess(response);
      } else {
        fbAuth.login(
          (res) => {
            if (res.status === "connected") {
              this.handleSuccess(res);
            } else {
              this.handleError(res);
            }
          },
          { scope: "email,public_profile" }
        );
      }
    });
  };

  handleSuccess = (res) => {
    this.props.onSignup(res);
  };

  handleError = (err) => {
    this.props.onError(err);
  };

  render() {
    const { t } = this.props;
    return (
      <button
        className="btn btn-sm btn-facebook btn-block"
        onClick={this.signIn}
      >
        {t("lobby.loginFacebook")}
      </button>
    );
  }
}

FacebookAuth.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const FacebookAuthWithTranslate = withTranslation()(FacebookAuth);
export default FacebookAuthWithTranslate;
