import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { AppContent, Loader } from "../../common";
// Event sagas
import { deleteOtherEvent } from "../../actions/";
import ActionMenu from "../../components/ActionMenu";
import confirm from "../../components/Confirm";
import OtherEventView from "./OtherEventView";

const OtherEventSingle = (props) => {
  const { t, isAuthenticated, event, user } = props;

  /* Other event types:
Vetenarian - Eläinlääkäri (Stetoskooppi?)
Massage - Kehonhuolto (Ihmisen kädet?)
Swim - Uinti (Aallot) Olisko myös vepe?
Obedience - Toko/tottis (Kapula)
Rally-obedience - rally-toko (Kylttiteline start?)
Joring - Vetolajit (valjaat)
Nosework - Nosework ()
Tracking - Jälki (Koiran nenä maassa?)
  */


  const subActions = [
    {
      label: t("common.edit"),
      onClick: () => {
        const { id } = props.match.params;
        props.history.push(`/event/edit/${id}`);
      },
    },
    {
      label: t("common.delete"),
      onClick: () => {
        confirm(t("event.deleteConfirm"), {
          title: t("common.areyousure"),
          ok: t("event.deleteOk"),
          cancel: t("common.cancel"),
        }).then(
          () => props.deleteOtherEvent(event),
          () => {}
        );
      },
    }];
     

  if (isAuthenticated && !event) return <Redirect to="/calendar" />;

  if (!event)
    return (
      <AppContent>
        <Loader />
      </AppContent>
    );

  return (
    <AppContent size="medium">
      <OtherEventView event={event} user={user} history={props.history} />
      <ActionMenu subActions={subActions} mainAction="event" />
    </AppContent>
  );
};

OtherEventSingle.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  event: PropTypes.object,
  deleteOtherEvent: PropTypes.func,
  dog: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.any,
  match: PropTypes.object,
};

const mapStateToProps = ({ auth, otherEvents, activeDog, user }, props) => {
  const { id } = props.match.params;
  const event = otherEvents.otherEvents.find((t) => t.id === parseInt(id, 10));
  const dog = activeDog;
  const u = user.user;
  return { isAuthenticated: auth.isAuthenticated, event, dog, user: u };
};

const OtherEventSingleWithTranslate = withTranslation()(OtherEventSingle);
export default connect(mapStateToProps, { deleteOtherEvent })(
  OtherEventSingleWithTranslate
);