import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { arrayMove } from "react-sortable-hoc";
import { ExerciseSortableList } from "./ExerciseSortableList";
import { Progress } from "../../common";
import { updateTrainingTopicNoNotification } from "../../actions";

/*
 * Gets id of a single topic as prop and renders exerciselist and progress bar.
 * Renders list from props because id of exercise changes when topic changes. Might slow down
 * sorting and checking, but has correct id for move to viewing.
 */
class ExerciseExpandedList extends Component {
  handleExerciseView = (exerciseId, e) => {
    e.stopPropagation();
    let topicId = this.props.topic.id;
    console.log(
      "Going to view exercise id: " + exerciseId + " of topic ID: " + topicId
    );
    this.props.history.push(
      `/training-topic/${topicId}/exercise/${exerciseId}`
    );
  };

  onExerciseSortEnd = ({ oldIndex, newIndex }) => {
    let newExerciseList = arrayMove(
      [...this.props.topic.exercises],
      oldIndex,
      newIndex
    );
    // Actually give them new orderNumbers
    newExerciseList = newExerciseList.map((item, index) => {
      item.orderNumber = index + 1;
      return item;
    });
    // Updates in database.
    this.props.updateTrainingTopicNoNotification({
      ...this.props.topic,
      exercises: newExerciseList,
    });
  };

  handleCheckChange = (e) => {
    e.stopPropagation();
    const id = parseInt(e.target.value, 10);
    const done = e.target.checked;
    let newExerciseList = [...this.props.topic.exercises];

    let exerciseIndex = this.props.topic.exercises.findIndex(
      (exercise) => exercise.id === id
    );
    newExerciseList[exerciseIndex].done = done;

    // Updates in database.
    this.props.updateTrainingTopicNoNotification({
      ...this.props.topic,
      exercises: newExerciseList,
    });
  };

  chevron = (expanded) => {
    return (
      <img
        src={require("../../images/icons/chevron-down.svg").default}
        className={`${
          expanded
            ? "icon center-icon rotated-icon "
            : "icon center-icon"
        }`}
        alt={"expand-contract"}
      />
    );
  };

  render() {
    // Counting percent of exercises done
    let allExercises = 0;
    let doneExercises = 0;
    for (let exercise of this.props.topic.exercises) {
      allExercises++;
      if (exercise.done) {
        doneExercises++;
      }
    }
    let percentDone = (100 * doneExercises) / allExercises;

    const { t } = this.props;
    let exercises = this.props.topic.exercises;
    let sortedExercises = exercises.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );

    return (
      <React.Fragment>
        {this.props.topic.expanded ? (
          <div onClick={(e) => e.stopPropagation()}>
            <ExerciseSortableList
              items={sortedExercises}
              onSortEnd={this.onExerciseSortEnd}
              onItemView={this.handleExerciseView}
              onCheck={this.handleCheckChange}
              topicList={true}
              useDragHandle={true}
              t={t}
            />
          </div>
        ) : (
          <div />
        )}
        <Progress
          percent={percentDone}
          topiclist={true}
          className="progress-sidemargin"
        />
        {this.chevron(this.props.topic.expanded)}
      </React.Fragment>
    );
  }
}

ExerciseExpandedList.propTypes = {
  updateTrainingTopicNoNotification: PropTypes.func,
  t: PropTypes.func,
  topic: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = ({ topics }, props) => {
  const id = props.topicId;
  const topic = topics.topics.find((t) => t.id === Number(id));
  topic.expanded = props.expanded;
  return {
    topic,
  };
};

const ExerciseExpandedListWithTranslate = withTranslation()(
  ExerciseExpandedList
);

export default withRouter(
  connect(mapStateToProps, {
    updateTrainingTopicNoNotification,
  })(ExerciseExpandedListWithTranslate)
);
