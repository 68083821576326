import React from "react";
import ReactDOM from "react-dom";

const serviceWorker = require("./config/service-worker");

import "./config/i18n";
import App from "./App";
import "./index.css";

// Register service worker with configuration options that respect the existing update flow
serviceWorker.register({
  // Don't show prompts by default - let the CheckForUpdate component handle it
  immediateUpdate: false,
  // Don't auto reload on controller change - let the CheckForUpdate component handle it
  autoReload: false,
  // Check for service worker updates every 3 hours
  checkInterval: 3 * 60 * 60 * 1000,
  onUpdate: (registration) => {
    // Just log that an update is available - the API version check will handle user-facing updates
    console.log("New version available through service worker");

    // Force update without prompting if API check hasn't already handled it
    if (registration && registration.waiting) {
      // Send skipWaiting message to activate the waiting worker
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // We won't reload here to avoid conflicts with the CheckForUpdate component
    }
  },
  onSuccess: (registration) => {
    console.log("AgiNotes has been cached for offline use.");
  },
});

const appElement = document.getElementById("root");

if (appElement) {
  ReactDOM.render(<App />, appElement);
}
