import { call, put, takeLatest, select } from "redux-saga/effects";

import {
  FETCH_OTHER_EVENTS_FILTERED,
  ADD_OTHER_EVENT,
  UPDATE_OTHER_EVENT,
  DELETE_OTHER_EVENT,
} from "../actions/types";
import {
  fetchOtherEventsFilteredSucceeded,
  fetchOtherEventsFilteredFailed,
  addOtherEventSucceeded,
  addOtherEventFailed,
  updateOtherEventSucceeded,
  updateOtherEventFailed,
  deleteOtherEventSucceeded,
  deleteOtherEventFailed,
  authExpired
} from "../actions";
import Api from "../helpers/Api";
import { getAuthToken, getActiveDog } from "./selectors";

// eslint-disable-next-line no-unused-vars
function* fetchOtherEventsFiltered({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const otherEvents = yield call(Api({ auth_token }).fetchOtherEventsFiltered, {
      dog,
      filters: payload.filters,
    });
    yield put(
      fetchOtherEventsFilteredSucceeded({
        otherEvents: otherEvents.data,
      })
    );
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchOtherEventsFilteredFailed({ status, statusText }));
  }
}
// eslint-disable-next-line no-unused-vars
function* addOtherEvent({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const otherEvent = yield call(
      Api({
        auth_token,
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      }).addOtherEvent,
      payload
    );
    yield put(addOtherEventSucceeded(otherEvent.data));
  } catch (e) {
    yield put(addOtherEventFailed(e.response.data.errors));
  }
}
// eslint-disable-next-line no-unused-vars
function* updateOtherEvent({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const response = yield call(
      Api({
        auth_token,
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      }).updateOtherEvent,
      payload
    );
    yield put(updateOtherEventSucceeded(response.data));
  } catch (e) {
    yield put(updateOtherEventFailed(e.response.data.errors));
  }
}
// eslint-disable-next-line no-unused-vars
function* deleteOtherEvent({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    yield call(Api({ auth_token }).deleteOtherEvent, payload);
    yield put(deleteOtherEventSucceeded(payload.id));
  } catch (e) {
    yield put(deleteOtherEventFailed(e.response.data.errors));
  }
}

function* otherEventsSaga() {
  yield takeLatest(FETCH_OTHER_EVENTS_FILTERED.REQUEST, fetchOtherEventsFiltered);
  yield takeLatest(ADD_OTHER_EVENT.REQUEST, addOtherEvent);
  yield takeLatest(UPDATE_OTHER_EVENT.REQUEST, updateOtherEvent);
  yield takeLatest(DELETE_OTHER_EVENT.REQUEST, deleteOtherEvent);
}

export default otherEventsSaga;