import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { fetchTrialsFiltered, fetchTrainingsFiltered, fetchOtherEventsFiltered } from "../../actions";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import daLocale from "@fullcalendar/core/locales/da";
import deLocale from "@fullcalendar/core/locales/de";
import esLocale from "@fullcalendar/core/locales/es";
import frLocale from "@fullcalendar/core/locales/fr";
import itLocale from "@fullcalendar/core/locales/it";
import nlLocale from "@fullcalendar/core/locales/nl";
import nbLocale from "@fullcalendar/core/locales/nb";
import plLocale from "@fullcalendar/core/locales/pl";
import ptLocale from "@fullcalendar/core/locales/pt";
import fiLocale from "@fullcalendar/core/locales/fi";
import svLocale from "@fullcalendar/core/locales/sv";
import { AppContent, Icon } from "../../common";
import PaywallNotification from "../../containers/App/PaywallNotification";
import ActionMenu from "../../components/ActionMenu";
import CalendarAddDialog from "./CalendarAddDialog";
import CalendarEventPopper from "./CalendarEventPopper";


const CalendarView = (props) => {
  const [eventOpen, setEventOpen] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);

  const [addOpen, setAddOpen] = useState(false);
  const [addInfo, setAddInfo] = useState(null);

  const [currentStart, setCurrentStart] = useState(null);
  const [demoEvents, setDemoEvents] = useState([]);

  const { trials, trainings, otherEvents, user, t, hasActivePlan } = props;
  let events = [];

  let colors = {
    red: "rgba(255, 99, 132, .5)",
    orange: "rgba(255, 159, 64, .5)",
    yellow: "rgba(255, 205, 86, .5)",
    green: "rgba(75, 192, 192, .5)",
    grey: "rgba(201, 203, 207, .5)",
    purple: "rgba(153, 102, 255, .5)"
  };

  // Add trials, trainings and other events with info for calendar 
  trials.forEach((trial) => {
    events.push({title: (trial.location ? trial.location : ""), 
      date: trial.date, 
      color: colors.red, 
      type:"trial", 
      extid: trial.id,
      trial:trial});
  });
  trainings.forEach((training) => {
    events.push({title: t("training." +training.trainingType), 
      date: training.date, 
      color:training.trainingType === "independent" ? colors.yellow : colors.orange, 
      type:"training", 
      extid: training.id, 
      training:training});
  });
  otherEvents.forEach((event) => {
    let color;
    if (event.eventType === "care") color = colors.green;
    if (event.eventType === "rest") color = colors.grey;
    if (event.eventType === "veterinarian") color = colors.purple;

    events.push({
      ...event,
      color: color,
      type:"health", 
      event:event
    });
  });

  console.log(events);

  // FullCalendar will call this function whenever it needs new event data. Triggered when the user clicks prev/next.
  // Also called whenever FullCalendar renders.
  // eslint-disable-next-line no-unused-vars
  const getEvents = (fetchinfo, successCallback, failureCallback) => {
    let start = moment(fetchinfo.start).format("YYYY-MM-DD");
    const end = moment(fetchinfo.end).format("YYYY-MM-DD");

    // Redux rerenders on successfull fetch which causes calendar to render and call function again.
    // If start date has not changed there is no need.
    if (start !== currentStart && (hasActivePlan || demoEvents.length === 0)) {
      // Demoevents for non premium - fetch only when demoEvents empty with early start date to get last ten.
      if (!hasActivePlan) start = "2010-01-01";

      props.fetchTrainingsFiltered({
        datesFilter: [start, end], 
      });
      props.fetchTrialsFiltered({
        datesFilter: [start, end],
      });
      props.fetchOtherEventsFiltered({
        datesFilter: [start, end],
      });
      setCurrentStart(start);
    }
    if (hasActivePlan) {
      successCallback(events);
    } else { // Non-premium
      if (demoEvents.length === 0 && events.length !== 0) {
        setDemoEvents(events);
      }     
      successCallback(demoEvents);
    }   
  };

  const renderEvent = (arg) => {
    let icon = "";
    if(arg.event.extendedProps.type === "trial") {
      icon = "medal";
    }
    if(arg.event.extendedProps.type === "training") {
      icon = "noteBook";
    }
    if(arg.event.extendedProps.eventType === "rest") {
      icon = "holiday";
    }
    if(arg.event.extendedProps.eventType === "veterinarian") {
      icon = "vetenarian";
    }
    if(arg.event.extendedProps.eventType === "care") {
      icon = "healthcare";
    }

    return (
      <Icon icon={icon} className="center-icon p-1" />       
    );
  };

  // Clicking event brings training or trial card visible.
  const eventClick = (info) => {
    setEventInfo({position: info.jsEvent, info:info.event.extendedProps}); 
    setEventOpen(true);
  };

  // Clicking date brings add event dialog visible.
  const dateClick = (dateClickInfo) => {
    setAddInfo({position: dateClickInfo.jsEvent, date: dateClickInfo.date});
    setAddOpen(true); 
  };

  return (
    <AppContent>
      <FullCalendar
        events={(fetchinfo, successCallback, failureCallback) => {getEvents(fetchinfo, successCallback, failureCallback);}}
        height={"auto"}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        eventDisplay='block'
        displayEventTime={false}
        eventClick={(info) => eventClick(info)}
        eventContent={(arg) => renderEvent(arg)}
        eventTextColor="black"
        dateClick={(dateClickInfo) => dateClick(dateClickInfo)}
        locales={[daLocale,deLocale,esLocale,frLocale,itLocale,nlLocale,nbLocale,plLocale,ptLocale,fiLocale,svLocale]}
        locale={user.language}
      />
      <CalendarEventPopper setEventOpen={setEventOpen} setEventInfo={setEventInfo} eventOpen={eventOpen}
        eventPosition={eventInfo?.position} eventInfo={eventInfo?.info} />
      <CalendarAddDialog hasActivePlan={hasActivePlan} setAddOpen={setAddOpen} 
        addOpen={addOpen} addDate={addInfo?.date} addPosition={addInfo?.position}/>
      <PaywallNotification/>
      <ActionMenu/>
    </AppContent>
  );
};

CalendarView.propTypes = {
  trials: PropTypes.arrayOf(PropTypes.object),
  trainings: PropTypes.arrayOf(PropTypes.object),
  otherEvents: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  fetchTrialsFiltered: PropTypes.func, 
  fetchTrainingsFiltered: PropTypes.func,
  fetchOtherEventsFiltered: PropTypes.func,
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
  hasActivePlan: PropTypes.bool
};

const mapStateToProps = ({ trials, trainings, otherEvents, user, subscriptions }) => ({
  trials: trials.trials,
  trainings: trainings.trainings,
  otherEvents: otherEvents.otherEvents,
  user: user.user,
  hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
});

const CalendarViewWithTranslate = withTranslation()(CalendarView);
export default connect(mapStateToProps, { fetchTrialsFiltered, fetchTrainingsFiltered, fetchOtherEventsFiltered })(CalendarViewWithTranslate);