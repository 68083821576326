import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { logout } from "../actions/AuthActions";
import { AppContent } from "../common";
import Fade from "./Fade";
import AuthRoute from "../routes/AuthRoute";
import LoginRoute from "../routes/LoginRoute";
import Lobby from "../containers/Auth/Lobby";
import Login from "../containers/Auth/Login";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Home from "../containers/Home/Home";
import DogList from "../containers/Dog/DogList";
import DogEdit from "../containers/Dog/DogEdit";
import TrainingTopicList from "../containers/TrainingTopic/TrainingTopicList";
import TrainingTopicEdit from "../containers/TrainingTopic/TrainingTopicEdit";
import SharedTrainingTopic from "../containers/TrainingTopic/SharedTrainingTopic";
import SharedSubTopicView from "../containers/TrainingTopic/SharedSubTopicView";
import SharedExerciseView from "../containers/TrainingTopic/SharedExerciseView";
import SubTopicEdit from "../containers/TrainingTopic/SubTopicEdit";
import ExerciseEdit from "../containers/TrainingTopic/ExerciseEdit";
import ExerciseView from "../containers/TrainingTopic/ExerciseView";
import TrainingDiaryList from "../containers/TrainingDiary/TrainingDiaryList";
import TrainingDiaryEdit from "../containers/TrainingDiary/TrainingDiaryEdit";
import TrainingDiarySingle from "../containers/TrainingDiary/TrainingDiarySingle";
import TrialList from "../containers/Trial/TrialList";
import TrialEdit from "../containers/Trial/TrialEdit";
import TrialSingle from "../containers/Trial/TrialSingle";
import CalendarView from "../containers/Calendar/CalendarView";
import OtherEventSingle from "../containers/OtherEvent/OtherEventSingle";
import OtherEventEdit from "../containers/OtherEvent/OtherEventEdit";
import Settings from "../containers/App/Settings";
import Terms from "../containers/App/Terms";
import Profile from "../containers/App/Profile";
import Subscription from "../containers/App/Subscription";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import AdminDashboard from "../containers/Admin/AdminDashboard";
import Page404 from "../components/Page404";
import OnboardingRoutes from "./OnboardingRoutes";
import ScrollToTop from "../routes/ScrollToTop";
import { ga } from "../config/env";
import GoogleAnalytics from "../routes/GoogleAnalytics";
import ErrorBoundary from "../common/ErrorBoundary";

class Routes extends Component {
  render() {
    const { activeDog, logout, isAuthenticated, history } = this.props;
    return (
      <Router history={history}>
        <GoogleAnalytics ga={ga}>
          <ScrollToTop>
            <Route
              render={({ location }) => (
                <Switch location={location}>
                  <LoginRoute exact path="/auth" component={Lobby} />

                  <Route path="/auth">
                    <div className="app">
                      <Header actions={false} />
                      <AppContent>
                        <Switch location={location}>
                          <LoginRoute
                            exact
                            path="/auth/forgot-password"
                            component={ForgotPassword}
                          />
                          <LoginRoute
                            exact
                            path="/auth/login"
                            component={Login}
                          />
                        </Switch>
                      </AppContent>
                    </div>
                  </Route>

                  <Route path="/signup" component={OnboardingRoutes} />

                  <Route>
                    <div className="app logged-in">
                      <div className="float-header-helper" />
                      <div className="float-header">
                        <Header
                          actions={isAuthenticated}
                          activeDog={activeDog}
                          onLogout={logout}
                        />
                        <Menu show={isAuthenticated} />
                      </div>
                      <ErrorBoundary>
                        <Fade animationKey={location.key}>
                          <Switch location={location}>
                            <AuthRoute exact path="/" component={Home} />
                            <AuthRoute
                              exact
                              path="/settings"
                              component={Settings}
                            />
                            <AuthRoute
                              exact
                              path="/subscription"
                              component={Subscription}
                            />
                            <AuthRoute
                              exact
                              path="/profile"
                              component={Profile}
                            />
                            <AuthRoute
                              exact
                              path="/accept-terms"
                              component={Terms}
                            />

                            <AuthRoute exact path="/dogs" component={DogList} />
                            <AuthRoute
                              exact
                              path="/dogs/add"
                              component={DogEdit}
                            />
                            <AuthRoute
                              exact
                              path="/dogs/:id"
                              component={DogEdit}
                            />

                            <AuthRoute
                              exact
                              path="/training-diary"
                              component={TrainingDiaryList}
                            />
                            <AuthRoute
                              exact
                              path="/training-diary/add"
                              component={TrainingDiaryEdit}
                            />
                            <AuthRoute
                              path="/training-diary/add/date/:date"
                              component={TrainingDiaryEdit}
                            />
                            <AuthRoute
                              path="/training-diary/add/:exerciseid"
                              component={TrainingDiaryEdit}
                            />
                            <AuthRoute
                              path="/training-diary/edit/:id"
                              component={TrainingDiaryEdit}
                            />
                            <Route
                              path="/training-diary/:id"
                              component={TrainingDiarySingle}
                            />

                            <AuthRoute
                              exact
                              path="/trials"
                              component={TrialList}
                            />
                            <AuthRoute
                              path="/trial/add/date/:date"
                              component={TrialEdit}
                            />
                            <AuthRoute
                              path="/trial/add"
                              component={TrialEdit}
                            />
                            <AuthRoute
                              path="/trial/edit/:id"
                              component={TrialEdit}
                            />
                            <AuthRoute
                              path="/trial/:id"
                              component={TrialSingle}
                            />

                            <AuthRoute
                              exact
                              path="/training-topics"
                              component={TrainingTopicList}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:topicid/:subtopicid/add"
                              component={ExerciseEdit}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:topicid/add"
                              component={ExerciseEdit}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:topicid/:subtopicid/edit/:id"
                              component={ExerciseEdit}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:topicid/:subtopicid/exercise/:id"
                              component={ExerciseView}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:topicid/edit/:id"
                              component={ExerciseEdit}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:topicid/exercise/:id"
                              component={ExerciseView}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:topicid/:subtopicid"
                              component={SubTopicEdit}
                            />
                            <AuthRoute
                              exact
                              path="/training-topic/:id"
                              component={TrainingTopicEdit}
                            />
                            <Route
                              exact
                              path="/shared/training-topic/:topicid/exercise/:id"
                              component={SharedExerciseView}
                            />
                            <Route
                              exact
                              path="/shared/training-topic/:topicid/:subtopicid/exercise/:id"
                              component={SharedExerciseView}
                            />
                            <Route
                              exact
                              path="/shared/training-topic/:topicid/:subtopicid"
                              component={SharedSubTopicView}
                            />
                            <Route
                              path="/shared/training-topic/:id"
                              component={SharedTrainingTopic}
                            />

                            <AuthRoute
                              path="/calendar"
                              component={CalendarView}
                            />
                            <AuthRoute
                              path="/event/add/date/:date"
                              component={OtherEventEdit}
                            />
                            <AuthRoute
                              path="/event/add"
                              component={OtherEventEdit}
                            />
                            <AuthRoute
                              path="/event/edit/:id"
                              component={OtherEventEdit}
                            />
                            <AuthRoute
                              path="/event/:id"
                              component={OtherEventSingle}
                            />

                            <AuthRoute
                              path="/admin"
                              component={AdminDashboard}
                            />

                            <Route component={Page404} />
                          </Switch>
                        </Fade>
                      </ErrorBoundary>
                    </div>
                  </Route>
                </Switch>
              )}
            />
          </ScrollToTop>
        </GoogleAnalytics>
      </Router>
    );
  }
}

const mapStateToProps = ({ auth, activeDog }) => ({
  onboarded: auth.onboarded,
  authToken: auth.auth_token,
  isAuthenticated: auth.isAuthenticated,
  activeDog: activeDog,
});

export default connect(mapStateToProps, { logout })(Routes);
