import {
  FETCH_OTHER_EVENTS_FILTERED,
  ADD_OTHER_EVENT,
  UPDATE_OTHER_EVENT,
  DELETE_OTHER_EVENT,
  LOGOUT,
  AFTER_REHYDRATE,
  SET_ACTIVE_DOG,
} from "../actions/types";

// eventTypes: rest, care, veterinarian, 

const INITIAL_STATE = {
  otherEvents: [],
  fetchingOtherEventsFiltered: false,
  saving: false,
  error: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  // Fetch
    case FETCH_OTHER_EVENTS_FILTERED.REQUEST:
      return { ...state, fetchingOtherEventsFiltered: true, error: null };
    case FETCH_OTHER_EVENTS_FILTERED.SUCCEEDED:
      return {
        ...state,
        fetchingOtherEventsFiltered: false,
        otherEvents: payload.otherEvents};
    case FETCH_OTHER_EVENTS_FILTERED.FAILED:
      return { ...state, fetchingOtherEventsFiltered: false, error: payload };


    // Add
    case ADD_OTHER_EVENT.REQUEST:
      return { ...state, saving: true, error: null };
    case ADD_OTHER_EVENT.SUCCEEDED:
      return {
        ...state,
        saving: false,
        otherEvents: [...state.otherEvents, payload],
      };
    case ADD_OTHER_EVENT.FAILED:
      return { ...state, saving: false, error: payload };

    // Update
    case UPDATE_OTHER_EVENT.REQUEST:
      return { ...state };
    case UPDATE_OTHER_EVENT.SUCCEEDED:
      return {
        ...state,
        otherEvents: state.otherEvents.map((t) =>
          t.id === payload.id ? payload : t
        ),
      };
    case UPDATE_OTHER_EVENT.FAILED:
      return { ...state };

    // Delete
    case DELETE_OTHER_EVENT.REQUEST:
      return { ...state };
    case DELETE_OTHER_EVENT.SUCCEEDED:
      return {
        ...state,
        otherEvents: state.otherEvents.filter((t) => t.id !== payload),
      };
    case DELETE_OTHER_EVENT.FAILED:
      return { ...state };

    
    case LOGOUT.REQUEST:
      return { ...INITIAL_STATE };

    case AFTER_REHYDRATE:
      return { ...state, saving: false, fetchingOtherEventsFiltered: false };

    case SET_ACTIVE_DOG:
      return INITIAL_STATE;

    default:
      return state;
  }
};

